import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

function AlertBar(props) {
    return (
        <div className="AlertBar" style={{ marginTop: 20, marginBottom: 20 }}>
        {
            props.alertMessage ? (
                <Alert variant="filled" severity={props.alertType.toLowerCase()}>
                    <AlertTitle>{props.alertType}</AlertTitle>
                    {props.alertMessage}
                </Alert>
            ) 
            : null
        }
        </div>
    )    
}

export default AlertBar;
