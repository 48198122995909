import React from 'react';
import { Grid, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import MUIdateTimePickers from '../components/MUIdateTimePickers';
import VesselPicker from '../components/VesselPicker';
import AlertBar from '../components/AlertBar';
import moment from 'moment';

import AuthContext from "../contexts/AuthProvider";

class Photos extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        // default selection
        const today = new Date();
        today.setHours(0, 0, 0);
        this.state = {
            selectedVessel: "",
            selectedDate: today,
            showLatest: "Y",
            records: [],
            dataLoad: false,
            alertType: "Info",
            alertMessage: false,
        };
    }

    handleVesselPickerCallback = (childData) => {
        console.log("Records: Selected new vessel > " + childData)
        this.setState({
            selectedVessel: childData,
        });
    }

    handleDatePickerSelectedDateCallback = (childData) => {
        // console.log("Records > start time > " + childData)
        this.setState({
            selectedDate: childData,
        });
    }

    handleSelectOptionChange = (event) => {
        this.setState({
            showLatest: event.target.value,
        });
    }

    loadRecord = () => {
        const { selectedVessel, selectedDate, showLatest } = this.state;
        
        fetch("/api/data/photos?unit_id=" + selectedVessel +
            "&selected_date=" + moment(selectedDate).format("YYYY-MM-DD") +
            "&latest=" + showLatest, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.length > 0) {
                    this.setState({
                        records: json,
                        dataLoad: true,
                        alertType: "Info",
                        alertMessage: false,
                    })
                } else {
                    this.setState({
                        dataLoad: false,
                        alertType: "Info",
                        alertMessage: "No data found!",
                    })
                }
            })
    }

    handleOnClick = () => {
        const { selectedVessel } = this.state;

        // Clear album
        this.setState({
            records: [],
            dataLoad: false,
        })        
        
        // Validate selections
        if (selectedVessel.length === 0) {
            this.setState({
                alertType: "Warning",
                alertMessage: "Please select a vessel!",
            })
            return
        }
        // selectedDate and showLatest have default values

        this.loadRecord()
    }

    onImageError = (e) => {
        e.target.src = 'broken_image.png';
    }

    render() {
        const { selectedDate, records, dataLoad, showLatest, alertType, alertMessage } = this.state;

        return (
            <div>
                { /* Selections */}
                <div className="RecordsOptions">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <VesselPicker parentCallback={this.handleVesselPickerCallback} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <MUIdateTimePickers initial={selectedDate} label="Date" parentCallback={this.handleDatePickerSelectedDateCallback} dateOnly={true} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl fullWidth>
                                <InputLabel id="album-or-latest">Selection</InputLabel>
                                <Select
                                    label="Selection"
                                    value={showLatest}
                                    onChange={this.handleSelectOptionChange}
                                >
                                    <MenuItem key="Latest" value="Y">Latest</MenuItem>
                                    <MenuItem key="Album" value="N">Album</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button variant='contained'
                                onClick={this.handleOnClick}
                            >Search</Button>
                        </Grid>
                    </Grid>
                </div>

                { /* Alert bar */ }
                <AlertBar alertType={alertType} alertMessage={alertMessage} />

                { /* Selected Results */}
                <div className="RecordsContent" style={{ marginTop: 10 }}>
                    <ImageList sx={{ width: "100%", height: "auto" }} cols={showLatest === "Y" ? 1 : 3} rowHeight="auto">
                        {
                            dataLoad ?
                                records.map((row) => (
                                    <React.Fragment>
                                        <ImageListItem key={row.photo_time}>
                                            <img
                                                src={row.photo_path}
                                                alt={new Date(row.photo_time).toLocaleString()}
                                                loading="lazy" 
                                                onError={this.onImageError}
                                            />
                                            <ImageListItemBar
                                                key={row.photo_time}
                                                title={new Date(row.photo_time).toLocaleString()}
                                                position="below"
                                            />
                                        </ImageListItem>
                                    </React.Fragment>
                                ))
                                : <></>
                        }

                    </ImageList>
                </div>
            </div>
        )
    }
}

export default Photos;