import {useState,useEffect} from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//import Badge from '@mui/material/Badge';
//import NotificationsIcon from '@mui/icons-material/Notifications';
import { MenuItem, Button, Menu} from '@mui/material';
import { MainListItems, SecondaryListItems } from './listItems';
import { useNavigate } from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import Summary from '../pages/Summary';
import Records from '../pages/Records';
import Draughts from '../pages/Draughts';
import Activities from '../pages/Activities';
import Map from '../pages/Map';
import Photos from '../pages/Photos';
import Vessels from '../pages/Vessels';
import Permits from '../pages/Permits';
import Zones from '../pages/Zones';
import Users from '../pages/Users';
import ChangePassword from '../pages/changePassword/ChangePassword';
import Copyright from './Copyright';

import {VERSION,BUILD} from '../constants';
import useAuth from '../hooks/useAuth';
import {ROLES} from '../constants';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function FrameworkWithSideBar(props) {

  const auth = useAuth();
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleChangePassword = () => {
    console.log("handleChangePassword");
    navigate('/changepassword',{replace:true});
  };

  const handleLogout = () => {
    auth.logout();
  };

  const handleOpenUserMenu = (event) => {
    console.log("handleOpenUserMenu");
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    console.log("handleCloseUserMenu");
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   if (!auth.isLoggedIn()) navigate("/login",{ replace: true });
  
  //   return () => {};
  // }, [auth]);

  useEffect(() => {
    let interval = 5000;

    const checkJWTWorkerID = setInterval(() => {
        // console.log("checking Login Status 5s");
        if (!auth.isLoggedIn())
        {
          navigate("/login",{replace:true});
        }
    }, interval);
  
    return () => clearInterval(checkJWTWorkerID);
  }, [navigate, auth]);


  useEffect(() => {
    let interval = 100;

    const checkJWTID_1 = setTimeout(() => {
        // console.log("checking Login Status 1st");
        if (!auth.isLoggedIn())
        {
          navigate("/login",{replace:true});
        }
    }, interval);
  
    return () => clearTimeout(checkJWTID_1);
  }, [navigate, auth]);
  
  
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              mSolution FEMU Web Portal - {props.title}
            </Typography>
            <Typography
              variant="body2"
            >
              Version {VERSION}, Build {BUILD}
            </Typography>
            {/*
            <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            */}
            {auth.isLoggedIn() && (
            <div>
              <Button 
                size="medium"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                color="inherit"
                style={{textTransform: 'none'}}
              >
                | {auth?.user?.displayName}
              </Button >
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseUserMenu}
              >
                { auth.hasRole([ROLES.ADMIN, ROLES.PERMIT_HOLDER, ROLES.BARGE_OWNER, ROLES.VIEWER]) ? (
                    <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
                    ):null
                }
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
              backgroundColor: '#1976D2',
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems />
            <Divider sx={{ my: 1 }} />
            <SecondaryListItems /> 
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {/* Which page to display */}
            { props.title === "Dashboard" ? <Dashboard /> : null }
            { props.title === "Summary" ? <Summary /> : null }
            { props.title === "Records" ? <Records /> : null }
            { props.title === "Draughts" ? <Draughts /> : null }
            { props.title === "Activities" ? <Activities /> : null }
            { props.title === "Map" ? <Map /> : null }
            { props.title === "Photos" ? <Photos /> : null }

            { props.title === "Vessels" ? <Vessels /> : null }
            { props.title === "Permits" ? <Permits /> : null }
            { props.title === "Zones" ? <Zones /> : null }
            { props.title === "Users" ? <Users /> : null }

            { props.title === "ChangePassword" ? <ChangePassword /> : null }
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default FrameworkWithSideBar;
/*
export default function Dashboard(props) {
  return <DashboardContent title={props.title} version={props.version} build={props.build}/>;
}
*/