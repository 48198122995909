import * as React from 'react';
import PermitList from './PermitList';
import EditPermit from './EditPermit';
import NewPermit from './NewPermit';

class Permits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curPage: 0,
            selectedPermit: false,
            cloneFromPermit: false,
        }
    }

    handlePermitListOnClickCallback = (permit_idx) => {
        this.setState({
            curPage: 1,
            selectedPermit: permit_idx,
        })
    }

    onAddPermit = (event, clone_idx) => {
        event.preventDefault();
        //console.log("Add new permit, clone from: " + clone_idx)
        this.setState({
            curPage: 2,
            cloneFromPermit: clone_idx,
        })
    }

    onBack = () => {
        this.setState({
            curPage: 0,
        })
    }

    getPage = () => {
        const { curPage, selectedPermit, cloneFromPermit } = this.state;

        switch (curPage) {
            case 0:
                return <PermitList parentCallback={this.handlePermitListOnClickCallback} onAddPermit={this.onAddPermit} />;
            case 1:
                return <EditPermit permit_idx={selectedPermit} onAddPermit={this.onAddPermit} onBack={this.onBack} />;
            case 2:
                return <NewPermit clone_idx={cloneFromPermit} onBack={this.onBack} />
            default:
                throw new Error("Unknown page");
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.getPage()}
            </React.Fragment>
        )
    }
}

export default Permits;