import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SearchIcon from '@mui/icons-material/Search';
import { visuallyHidden } from '@mui/utils';
import { Button, MenuItem, Select, TextField, FormControl, InputLabel } from '@mui/material';

import AuthContext from "../contexts/AuthProvider";
import {ROLES} from "../constants";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'zone_name',
        label: 'Zone Name',
        filter: 'input',
    },
    {
        id: 'zone_type',
        label: 'Zone Type',
        filter: 'select',
        options: [
            {
                value: '%',
                desc: 'All',
            },
            {
                value: 'D',
                desc:'Dumping',
            }, 
            {
                value: 'L',
                desc: 'Loading',
            },
        ],
    },
    {
        id: 'zone_status',
        label: 'Zone Status',
        filter: 'select',
        options: [
            {
                value: '%',
                desc: 'All',
            },
            {
                value: 'A',
                desc:'Active',
            }, 
            {
                value: 'S',
                desc: 'Suspended',
            },
        ],
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, onAddZone, isAdmin } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell
                    key='Action'
                    align='center'
                >
                    { isAdmin ? (
                        <Button variant='contained' onClick={event => onAddZone(event, false)} >
                            New
                        </Button>
                        ) : ( 'View' )
                    }
                </TableCell>
            </TableRow>
            <TableRow
                style={{
                    height: 30,
                }}
            >
                {headCells.map((headCell) => (
                    <TableCell
                        key={"filterCell_" + headCell.id}
                        align='left'
                    >
                        {
                            headCell.filter === 'input' ? (
                                <TextField
                                    id={"filtertext_" + headCell.id}
                                    label="Filter"
                                    variant='outlined'
                                    size="small"
                                    margin="none"
                                    onBlur={event => props.parentFilterCallback(event, headCell.id)}
                                />    
                            ) : null
                        }
                        {
                            headCell.filter === 'select' ? (
                                <FormControl sx={{ m: 1, minWidth: 150, marginLeft: 0 }} size="small">
                                    <InputLabel>Filter</InputLabel>
                                    <Select
                                        id={"filtertext_" + headCell.id}
                                        label="Filter"
                                        defaultValue=""
                                        onChange={event => props.parentFilterCallback(event, headCell.id)}
                                    >
                                        {headCell.options.map(({value, desc}) => (
                                            <MenuItem key={value} value={value}>{desc}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : null
                        }
                    </TableCell>
                ))}
                <TableCell
                    key='Filter'
                    align='center'
                >
                    <SearchIcon
                        onClick={props.parentSearchCallback}
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    /* onSelectAllClick: PropTypes.func.isRequired, */
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

class ZoneList extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: 'zone_name',
            selected: [],
            page: 0,
            rowsPerPage: 10,
            zones: false,
            filters: {},
        };
    }

    handleRequestSort = (event, property) => {
        const { order, orderBy } = this.state;
        const isAsc = orderBy === property && order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property,
        });
    }

    componentDidMount() {
        const  { zones } = this.state;
        
        if (zones === false) {
            fetch("/api/zone/list", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    zones: json,
                })
            });
        }
    }

    handleClick = (event, name) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({
            selected: newSelected,
        });
    }


    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    isSelected = (name) => {
        return this.selected.indexOf(name) !== -1;
    }

    handleEditOnClick = (event, zone_idx) => {
        //console.log("handleEditOnClick: permit_idx = " + permit_idx);
        this.props.parentCallback(zone_idx);
    }

    handleFilterOnBlur = (event, filter_id) => {
        let { filters } = this.state;
        //console.log("handleFilterOnBlur: (filter_id, value) = (" + filter_id + ", " + event.target.value + ")");
        filters[filter_id] = event.target.value;
    }

    handleFilterOnSearch = () => {
        const { filters } = this.state;
        //console.log("Let's search!");
        //console.log(filters);
        //console.log(JSON.stringify(filters));
        fetch('/api/zone/list', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
            body: JSON.stringify(filters),
        })
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                zones: json,
            })
        });
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    emptyRows() {
        return this.page > 0 ? Math.max(0, (1 + this.page) * this.rowsPerPage - this.permits.length) : 0;
    }

    render() {
        const { zones, selected, order, orderBy, page, rowsPerPage } = this.state;

        if (zones === false) return (<div></div>);
        
        const isAdmin = this.context.hasRole([ROLES.ADMIN]);

        return (
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table
                            //sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size='medium'
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                parentFilterCallback={this.handleFilterOnBlur}
                                parentSearchCallback={this.handleFilterOnSearch}
                                onAddZone={this.props.onAddZone}
                                rowCount={zones.length}
                                isAdmin={isAdmin}
                            />
                            <TableBody>
                                {stableSort(zones, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        //const isItemSelected = this.isSelected(row.name);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                //onClick={(event) => this.handleClick(event, row.idx)}
                                                tabIndex={-1}
                                                key={row.idx}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                >
                                                    {row.zone_name}
                                                </TableCell>
                                                <TableCell>{row.zone_type === 'D' ? "Dumping" : "Loading"}</TableCell>
                                                <TableCell>{row.zone_status === 'A' ? "Active" : "Suspended"}</TableCell>
                                                <TableCell
                                                    onClick={event => this.handleEditOnClick(event, row.idx)}
                                                    align='center'
                                                >
                                                    <ModeEditIcon />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {this.emptyRows() > 0 && (
                                    <TableRow
                                        style={{
                                            height: 53 * this.emptyRows(),
                                        }}
                                    >
                                        <TableCell colSpan={3} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            sx={{ align: "right" }}
                            count={zones.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                </Paper>
            </Box>
        );
    }
}

export default ZoneList;