import React from 'react';
import { FormControl, InputLabel, Select, MenuItem  } from '@mui/material';

import AuthContext from "../contexts/AuthProvider";
//import {ROLES} from '../constants';

class VesselPicker extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            selectedVessel: "",
            vesselList: [],
            dataLoaded: false,
            allowAll: props.allowAll === null ? false : props.allowAll,
        };
    }

    handleChange = (event) => {
        //console.log("VesselPicker > Vessel selected: " + event.target.value);
        this.setState({ selectedVessel: event.target.value });
        this.props.parentCallback(event.target.value);
    }

    componentDidMount() {
        var requestURL = "/api/vessel/names";
        //console.log("User role: " + this.context.hasRole([ROLES.ADMIN]));
        // if (this.context.hasRole([ROLES.ADMIN]) === undefined) {
        //     //console.log("Not admin user")
        //     requestURL += "?username=" + this.context.user.username;
        // }

        fetch(requestURL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
        })
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                vesselList: json,
                dataLoaded: true,
            })
            //console.log(this.state) 
        })
    }

    render() {
        const { selectedVessel, dataLoaded, vesselList, allowAll } = this.state;
        //console.log(this.state)
    
        if (!dataLoaded) return <div></div>;

        return (
            <FormControl fullWidth>
                <InputLabel id="femu-id-select-label">Vessel</InputLabel>
                <Select
                    label="Select Vessel"
                    value={selectedVessel}
                    onChange={this.handleChange}
                >
                {
                    allowAll ?
                        <MenuItem key="" value="0">All vessels</MenuItem>
                        : null
                }
                {
                    dataLoaded ?
                        vesselList.map((row) => (
                            <MenuItem key={row.unit_id} value={row.unit_id}>{row.vessel_name}</MenuItem>
                        ))
                        : null
                }
                </Select>
            </FormControl>
        );    
    }
}

export default VesselPicker;
