import React, { useState, useRef, useEffect}  from 'react';
import Button from '@mui/material/Button';
import {Alert,Avatar,TextField,Typography,Box} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Copyright from '../../components/Copyright';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

import "./login.scss";

const Login = () => {
    const SYSTEM_NAME = "FEMU Web Portal";

    const auth = useAuth(); 

    const navigate = useNavigate();
    const from = "/Dashboard";

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg  , setErrMsg  ] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const usernameRef = useRef();
    const passwordRef = useRef();
    const errRef   = useRef();

    useEffect(() => {
        usernameRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    useEffect(() => {
        // console.log(auth);
        if (auth.isLoggedIn()) { navigate(from, { replace: true }) };
    
        return () => {}
    }, [auth,navigate])
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        let hasErr = false;

        if ( !username ) {
            hasErr = true;
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }

        if ( !password) {
            hasErr = true;
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }

        if ( hasErr ) return;

        try{
            await auth.login(username,password);
            setUsername('');
            setPassword('');
        }
        catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
        }
    }

    return (
        <div id='wrapper'>
            <div id='divLogin'>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {SYSTEM_NAME}
                </Typography>
                <Typography component="h2" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField 
                        id="username"
                        ref={usernameRef} 
                        label="Username" 
                        variant="outlined" 
                        name="username"
                        required
                        fullWidth
                        autoFocus
                        error={usernameError}
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                    />
                    <TextField 
                        id="password" 
                        ref={passwordRef} 
                        label="Password"
                        name="password"
                        variant="outlined" 
                        required
                        type="password"
                        error={passwordError}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                    <Button type="submit" variant="contained" >Login</Button>
                </Box>
                {
                    errMsg?
                        (<Alert ref={errRef} severity="error">{errMsg}</Alert>)
                        :
                        (<></>)
                }
                <Copyright  sx={{ mt: 8, mb: 4 }} />
            </div>
        </div>
    );
};
  
export default Login;
  