import React from 'react';
import { Grid, Button, Paper } from '@mui/material';
import MUIdateTimePickers from '../components/MUIdateTimePickers';
import VesselPicker from '../components/VesselPicker';
import AlertBar from '../components/AlertBar';
import moment from 'moment';

import { useTheme } from '@mui/material/styles';
import { XAxis, YAxis, Label, ResponsiveContainer, ScatterChart, Scatter, Tooltip, ZAxis } from 'recharts';

import AuthContext from "../contexts/AuthProvider";

// Generate Sales Data
function createChartData(time, draught) {
    return { time, draught };
}

let chartData = [
//    createChartData(new Date('2023-02-20 00:00').getTime(), undefined),
//    createChartData(new Date('2023-02-21 00:00').getTime(), undefined),
];

function ReScatterChart() {
    //console.log("ScatterChart")
    //console.log(chartData)
    const theme = useTheme();

    return (
        <React.Fragment>
            <ResponsiveContainer>
                <ScatterChart
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis
                        dataKey = 'time'
                        domain = {['dataMin', 'dataMax']}
                        name = 'Time'
                        tickFormatter = {(unixTime) => moment(unixTime).format('DD/MM HH:mm')}
                        type = 'number' 
                    />
                    <YAxis 
                        dataKey = 'draught'
                        domain = {[0, dataMax => Math.floor(dataMax) + 1]}
                        name = 'Draught'
                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{
                                textAnchor: 'middle',
                                fill: theme.palette.text.primary,
                                ...theme.typography.body1,
                            }}
                        >
                            Draught (m)
                        </Label>
                    </YAxis>
                    <ZAxis 
                        range={[10,10]} 
                    />
                    <Tooltip 
                        cursor={{ strokeDasharray: '3 3'}} 
                        formatter = {(value, name) => name === 'Time' ? moment(value).format("YYYY-MM-DD HH:mm:ss") : value}
                    />
                    <Scatter
                        data = {chartData}
                        line = {{stroke: '#1976D2'}}
                        lineJointType = 'monotoneX'
                        lineType = 'joint'
                        name = 'Draughts'
                        fill = '#1976D2'
                    />
                </ScatterChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}



class Draughts extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        // default selection
        const today = new Date();
        today.setHours(0, 0, 0);
        const tomorrow = new Date();
        tomorrow.setHours(24, 0, 0);
        this.state = {
            selectedVessel: "",
            startDate: today,
            endDate: tomorrow,
            records: [],
            dataLoad: false,
            alertType: "info",
            alertMessage: false,
        };
    }

    handleVesselPickerCallback = (childData) => {
        console.log("Records: Selected new vessel > " + childData)
        this.setState({
            selectedVessel: childData,
        })
    }

    handleDateTimePickerStartTimeCallback = (childData) => {
        // console.log("Records > start time > " + childData)
        this.setState({
            startDate: childData,
        })
    }

    handleDateTimePickerEndTimeCallback = (childData) => {
        // console.log("Records > end time > " + childData)
        this.setState({
            endDate: childData,
        })
    }

    handleOnClick = () => {
        const { selectedVessel, startDate, endDate } = this.state;

        // Clear graph
        this.setState({
            dataLoad: false,
        })

        if (selectedVessel.length === 0) {
            this.setState({
                alertType: "Warning",
                alertMessage: "Please select a vessel!",
            })
            return
        }
        // StartDate and endDate have default values
        if (startDate > endDate) {
            this.setState({
                alertType: "Error",
                alertMessage: "Invalid selections: Start date must be earlier than end date!",
            })
            return
        }
        if (((endDate - startDate) / 86400000) > 7) {
            this.setState({
                alertType: "Error",
                alertMessage: "Selection period cannot exceed 7 days!",
            })
            return
        }

        this.loadRecord()
    }

    loadRecord = () => {
        const { selectedVessel, startDate, endDate } = this.state;

        // Load data
        fetch("/api/data/draughts?unit_id=" + selectedVessel +
            "&start_time=" + moment(startDate).format("YYYY-MM-DD HH:mm:ss") +
            "&end_time=" + moment(endDate).format("YYYY-MM-DD HH:mm:ss"), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                chartData = []
                if (json.length > 0) {
                    json.map((row) => (
                        chartData.push( createChartData(new Date(row.hk_time).getTime(), row.draught_meter) )
                    ))
                    this.setState({
                        dataLoad: true,
                        alertType: "Info",
                        alertMessage: false,
                    })
                } else {
                    this.setState({
                        dataLoad: false,
                        alertType: "Info",
                        alertMessage: "No data found!",
                    })
                }
            })
    }

    render() {
        const { startDate, endDate, dataLoad, alertType, alertMessage } = this.state;

        return (
            <div>
                { /* Selections */}
                <div className="RecordsOptions">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <VesselPicker parentCallback={this.handleVesselPickerCallback} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <MUIdateTimePickers initial={startDate} label="Start" parentCallback={this.handleDateTimePickerStartTimeCallback} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <MUIdateTimePickers initial={endDate} label="End" parentCallback={this.handleDateTimePickerEndTimeCallback} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button variant='contained'
                                onClick={this.handleOnClick}
                            >Search</Button>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <AlertBar alertType={alertType} alertMessage={alertMessage} />
                        </Grid>

                        { /* Selected Results */
                            dataLoad ? (
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 400,
                                        }}>
                                        <ReScatterChart />
                                    </Paper>
                                </Grid>    
                            )
                            : null
                        }
                    </Grid>
                </div>
            </div>
        );
    }
}

export default Draughts;