import React from "react";
import {
    Grid, Button, FormControl, InputLabel, Select, MenuItem, TextField,
    Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions
} from '@mui/material';
import MUIdateTimePickers from '../components/MUIdateTimePickers';
import AlertBar from '../components/AlertBar';
import styled from "styled-components";
import moment from "moment";
import PreviewMap from "../components/PreviewMap";

import AuthContext from "../contexts/AuthProvider";
import {ROLES} from "../constants";

export const GridBreak = styled.div`
    width: 100%
`;

class EditPermit extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props)
        this.state = {
            permit_idx: props.permit_idx,
            permitInfo: false,
            updatedInfo: false,     /* a copy of existing info for storing and checking any change */
            alertType: "Info",
            alertMessage: "Please make sure the information is correct before update!",
            dialogOpen: false,
            vesselList: false,
            zoneList: false,
            zoneVertex: false,
        }
    }

    loadZoneVertex(zone_idx) {
        fetch("/api/zone/detail/" + zone_idx, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },    
        })
        .then((res) => res.json())
        .then((json) => {
            //console.log(json)
            if (json.length > 0) {
                this.setState({
                    zoneVertex: JSON.parse(JSON.stringify(json[0].vertex)),
                })
            }
        })
    }

    componentDidMount() {
        const { permit_idx, permitInfo, vesselList, zoneList } = this.state;

        if (permitInfo === false) {
            fetch("/api/permit/detail/" + permit_idx, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                //console.log(json)
                if (json.length > 0) {
                    this.setState({
                        permitInfo: json[0],
                        updatedInfo: { ...json[0] },
                    })
                    /* Get zone vertex */
                    this.loadZoneVertex(json[0].zone_idx);
                }
            })
        }

        if (vesselList === false) {
            fetch("/api/vessel/names", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    vesselList: json,
                })
            })
        }

        if (zoneList === false) {
            fetch("/api/zone/names", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    zoneList: json,
                })
            })
        }
    }

    handleTextFieldOnBlur = (event) => {
        let { updatedInfo } = this.state;
        updatedInfo[event.target.id] = event.target.value;
    }

    handleCheckboxOnChange = (event) => {
        let { updatedInfo } = this.state;
        updatedInfo[event.target.id] = event.target.checked ? 'Y' : 'N';
    }

    handleSelectOnChange = (event, field_id) => {
        let { updatedInfo } = this.state;
        updatedInfo[field_id] = event.target.value;
        if (field_id === 'zone_idx') {
            this.loadZoneVertex(event.target.value);
        }
    }

    handleDatePickerSelectedDateCallback = (newValue, field_id) => {
        let { updatedInfo } = this.state;
        console.log("handleDatePickerSelectedDateCallback : " + field_id)
        updatedInfo[field_id] = new Date(newValue).toISOString();
    }

    handleButtonOnBack = () => {
        const { permitInfo, updatedInfo } = this.state;
        console.log(updatedInfo);
        if (JSON.stringify(permitInfo) === JSON.stringify(updatedInfo)) {
            // No change, go back
            this.props.onBack();
        } else {
            this.setState({
                dialogOpen: true,
            })
        }
    }

    handleDialogClose = (event) => {
        this.setState({
            dialogOpen: false,
        })
        if (event.target.value === "discard") {
            this.props.onBack();
        }
    }

    // ====================================
    // Validations
    fieldValidations(info) {
        const regExNoSpChar = new RegExp(/^[^*<>!%'"+=;^$]*$/);         // Not contains special characters
        const regExGenString = new RegExp(/^[0-9A-Z-()/]*$/);           // Digits, hyphen, slash and Upper letters

        // Check permit number should contain digits and uppercase letters and max. 20 characters
        if (!regExGenString.test(info.permit_number) || (info.permit_number.length === 0) || (info.permit_number.length > 20)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Invalid permit number syntax or cannot be empty and maximum 20 characters!",
            })
            return false;
        }

        // Check permit number should contain digits and uppercase letters and max. 200 characters
        if (!regExNoSpChar.test(info.permit_holder) || (info.permit_holder.length === 0) || (info.permit_holder.length > 200)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Permit holder should not contain special character, cannot be empty and maximum 200 characters!",
            })
            return false;
        }

        // Check start date
        if (info.start_date === null) {
            this.setState({
                alertType: "Error",
                alertMessage: "Please input Start Date!",
            })
            return false;
        }

        // Check end date
        if (info.end_date === null) {
            this.setState({
                alertType: "Error",
                alertMessage: "Please input End Date!",
            })
            return false;
        }

        // Check start date and end date relationship
        if (info.start_date > info.end_date) {
            this.setState({
                alertType: "Error",
                alertMessage: "Invalid selections: Start date must be earlier than end date!",
            })
            return false;
        }

        // Check vessel associated
        if (info.vessel_idx.length === 0) {
            this.setState({
                alertType: "Error",
                alertMessage: "Please select Vessel!",
            })
            return false;
        }

        // Check zone associated
        if (info.zone_idx.length === 0) {
            this.setState({
                alertType: "Error",
                alertMessage: "Please select Dumping Zone!",
            })
            return false;
        }

        // Check issue date
        if (info.issue_date === null) {
            this.setState({
                alertType: "Error",
                alertMessage: "Please input Issue Date!",
            })
            return false;
        }
        return true;
    }

    handleButtonOnSaveChanges = () => {
        const { permitInfo, updatedInfo } = this.state;
        
        // Validate inputs
        if (!this.fieldValidations(updatedInfo)) return;

        // All passed
        if (JSON.stringify(permitInfo) === JSON.stringify(updatedInfo)) {
            // No change, show warning
            this.setState({
                alertType: "Warning",
                alertMessage: "No change found, nothing to save!",
            })
        } else {
            fetch('/api/permit/update', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },
                body: JSON.stringify(updatedInfo),
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    alertType: json.Result,
                    alertMessage: json.Message,
                })
                if (json.Result === "Success") {
                    setTimeout(this.props.onBack, 2000);
                }
            })
        }

    }


    render() {
        const { permit_idx, permitInfo, vesselList, zoneList, zoneVertex, alertType, alertMessage, dialogOpen } = this.state;

        /* Data not loaded yet */
        if (permitInfo === false) return (<div></div>)

        const isAdmin = this.context.hasRole([ROLES.ADMIN]);

        return (
            <div>
                { /* Alert bar */
                  isAdmin ?
                    <AlertBar alertType={alertType} alertMessage={alertMessage} />
                  : null
                }

                { /* Dialog */}
                <Dialog
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        Discard changes?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog_description">
                            Change(s) detected, discard changes without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button value="discard" onClick={this.handleDialogClose}>Discard</Button>
                        <Button value="keep_editing" onClick={this.handleDialogClose} autoFocus>Keep Editing</Button>
                    </DialogActions>
                </Dialog>

                { /* Form */}
                <div className="EditForm">
                    <Grid container rowSpacing={5} columnSpacing={3}>
                        { /* Large screen row 1 */}
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="permit_number"
                                name="permit_number"
                                label="Permit Number"
                                fullWidth
                                variant="standard"
                                defaultValue={permitInfo.permit_number}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={8} lg={9}>
                            <TextField
                                required
                                id="permit_holder"
                                name="permit_holder"
                                label="Permit Holder"
                                fullWidth
                                variant="standard"
                                defaultValue={permitInfo.permit_holder}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>

                        <GridBreak />
                        
                        <Grid item xs={12} md={12} lg={6}>
                            <Grid container rowSpacing={5} columnSpacing={3}>
                                { /* Large screen row 2 */}
                                <Grid item xs={12} md={6} lg={6}>
                                    { /* Start date */}
                                    <MUIdateTimePickers
                                        label="Effective From *"
                                        dateOnly={false}
                                        initial={permitInfo.start_date}
                                        parentCallback={newValue => this.handleDatePickerSelectedDateCallback(newValue, 'start_date')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    { /* End Date */}
                                    <MUIdateTimePickers
                                        label="Valid Until *"
                                        dateOnly={false}
                                        initial={permitInfo.end_date}
                                        parentCallback={newValue => this.handleDatePickerSelectedDateCallback(newValue, 'end_date')}
                                    />
                                </Grid>

                                { /* Large screen row 3 */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <FormControl required fullWidth>
                                        <InputLabel>Vessel</InputLabel>
                                        {
                                            vesselList ? (
                                                <Select
                                                    label="Vessel"
                                                    defaultValue={permitInfo.vessel_idx}
                                                    onChange={event => this.handleSelectOnChange(event, "vessel_idx")}
                                                >
                                                    {
                                                        vesselList.map((row) => (
                                                            <MenuItem key={row.idx} value={row.idx}>{row.vessel_name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            ) : null
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    { /* Surrender Date */}
                                    <MUIdateTimePickers
                                        label="Surrender Date"
                                        dateOnly={true}
                                        initial={permitInfo.surrender_date}
                                        parentCallback={newValue => this.handleDatePickerSelectedDateCallback(newValue, 'surrender_date')}
                                    />
                                </Grid>

                                <GridBreak />

                                { /* Large screen row 4 */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <FormControl required fullWidth>
                                        <InputLabel>Dumping Zone</InputLabel>
                                        {
                                            zoneList ? (
                                                <Select
                                                    label="Dumping Zone"
                                                    defaultValue={permitInfo.zone_idx}
                                                    onChange={event => this.handleSelectOnChange(event, "zone_idx")}
                                                >
                                                    {
                                                        zoneList.map((row) => (
                                                            <MenuItem key={row.idx} value={row.idx}>{row.zone_name}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            ) : null
                                        }
                                    </FormControl>
                                </Grid>

                                <GridBreak />

                                { /* Large screen row 5 */}
                                <Grid item xs={12} md={6} lg={6}>
                                    { /* Issue Date */}
                                    <MUIdateTimePickers
                                        label="Issue Date *"
                                        dateOnly={true}
                                        initial={permitInfo.issue_date}
                                        parentCallback={newValue => this.handleDatePickerSelectedDateCallback(newValue, 'issue_date')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        id="input_date"
                                        name="input_date"
                                        label="Created on"
                                        fullWidth
                                        variant="standard"
                                        defaultValue={moment(permitInfo.input_date).format("YYYY-MM-DD")}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                {/*
                                <Grid item xs={12} md={4} lg={3}>
                                    <TextField
                                        id="last_update"
                                        name="last_update"
                                        label="Last update at"
                                        fullWidth
                                        variant="standard"
                                        defaultValue={moment(permitInfo.last_update).format("MM/DD/YYYY HH:mm")}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                */}
                                <GridBreak />

                                <Grid item xs={12} md={12} lg={7}>
                                    <Button variant="outlined" onClick={this.handleButtonOnBack}>
                                        Back
                                    </Button>
                                    <span>&nbsp;&nbsp;</span>
                                    { isAdmin ?
                                        <Button variant="contained" onClick={this.handleButtonOnSaveChanges}>
                                            Save Changes
                                        </Button>
                                    : null
                                    }
                                </Grid>
                                { isAdmin ?
                                    <Grid item xs={12} md={12} lg={5}>
                                        <Button variant="outlined" onClick={event => this.props.onAddPermit(event, permit_idx)}>
                                            Clone New
                                        </Button>
                                    </Grid>
                                  : null
                                }
                            </Grid>

                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <Grid item xs={12} md={12} lg={12}>
                                <PreviewMap vertex={zoneVertex}/>
                            </Grid>
                        </Grid>


                    </Grid>
                </div>
            </div>
        )
    }
}

export default EditPermit;