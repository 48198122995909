import React from "react";
import {
    Grid, Button, FormControl, InputLabel, Select, MenuItem, TextField,
    Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions,
    OutlinedInput, Checkbox, ListItemText, Box, Chip
} from '@mui/material';
import AlertBar from '../components/AlertBar';
import styled from "styled-components";

import AuthContext from "../contexts/AuthProvider";

export const GridBreak = styled.div`
    width: 100%
`;


class EditUser extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props)
        this.state = {
            userID: props.userID,
            userInfo: false,
            updatedInfo: false,     /* a copy of existing info for storing and checking any change */
            alertType: "Info",
            alertMessage: "Please make sure the information is correct before update!",
            dialogOpen: false,
            userRoles: false,
            vesselNames: false,
            permitNames: false,
            selectedVessel: [],
            selectedPermit: [],
            existingUserList: false,
        }
    }

    componentDidMount() {
        const { userID, userInfo, vesselNames, permitNames, userRoles, existingUserList } = this.state;

        if (userInfo === false) {
            fetch("/api/user/detail/" + userID, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                //console.log(json)
                if (json.length > 0) {
                    this.setState({
                        userInfo: json[0],
                        updatedInfo: { ...json[0] },
                        selectedVessel: json[0].accessible_vessel_idx !== null ? json[0].accessible_vessel_idx.split(',') : [],
                        selectedPermit: json[0].accessible_permit_idx !== null ? json[0].accessible_permit_idx.split(',') : [],
                    })
                }
            })
        }

        if (vesselNames === false) {
            fetch("/api/vessel/names", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                // convert return json to dictionary [idx]->name
                var vessels = {};
                json.map((row) => (
                    vessels[row.idx] = row.vessel_name
                ))
                this.setState({
                    vesselNames: vessels,
                })
            })
        }

        if (permitNames === false) {
            fetch("/api/permit/names", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                // convert return json to dictionary [idx]->name
                var permits = {};
                json.map((row) => (
                    permits[row.idx] = row.permit_number
                ))
                this.setState({
                    permitNames: permits,
                })
            })
        }

        if (userRoles === false) {
            fetch("/api/user/roles", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    userRoles: json,
                })
            })
        }

        if (existingUserList === false) {
            fetch("/api/user/names", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    existingUserList: json,
                })
            })
        }
    }

    handleTextFieldOnBlur = (event) => {
        let { updatedInfo } = this.state;
        updatedInfo[event.target.id] = event.target.value;
    }

    handleSelectOnChange = (event, field_id) => {
        let { updatedInfo } = this.state;
        updatedInfo[field_id] = event.target.value;
    }

    handleButtonOnBack = () => {
        const { userInfo, updatedInfo } = this.state;
        //console.log(updatedInfo);
        if (JSON.stringify(userInfo) === JSON.stringify(updatedInfo)) {
            // No change, go back
            this.props.onBack();
        } else {
            this.setState({
                dialogOpen: true,
            })
        }
    }

    handleDialogClose = (event) => {
        this.setState({
            dialogOpen: false,
        })
        if (event.target.value === "discard") {
            this.props.onBack();
        }
    }

    // ====================================
    // Validations
    fieldValidations(info) {
        const { existingUserList } = this.state;

        const regExNoSpChar = new RegExp(/^[^*<>!%'"+=;^$]*$/);         // Not contains special characters
        const password_regex=  /^(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{10,50}$/;
        const regExPwd = new RegExp(password_regex);
        var validationFailed = false;

        // Check if username valid and not duplicated
        if (!regExNoSpChar.test(info.username) || info.username === undefined || info.username.length > 15) {
            this.setState({
                alertType: "Error",
                alertMessage: "Login ID should not contain special character, cannot be empty and maximum 15 characters!",
            })
            return false;
        }
        existingUserList.forEach(existing => {
            if (existing.username === info.username) {
                if (existing.userID !== info.userID) {
                    console.log("Duplicated Login ID");
                    this.setState({
                        alertType: "Error",
                        alertMessage: `Login ID ${info.username} has been used, please use an unique login ID!`,
                    })
                    validationFailed = true;
                    return;
                }
            }
        });
        if (validationFailed) return false;

        // Check if display name valid and maximum 45 characters
        if (!regExNoSpChar.test(info.displayName) || info.displayName === undefined || info.displayName.length > 45) {
            this.setState({
                alertType: "Error",
                alertMessage: "User name should not contain special character, cannot be empty and maximum 45 characters!",
            })
            return false;
        }

        if (info.resetPassword.length > 0) {
            if (!regExPwd.test(info.resetPassword)) {
                this.setState({
                    alertType: "Error",
                    alertMessage: "Password should at least 8 characters, with 1 uppercase, 1 lowercase, 1 digit and 1 special character!",
                })
                return false;
            }

            if (info.resetPassword !== info.confirmPassword) {
                this.setState({
                    alertType: "Error",
                    alertMessage: "Password unmatch!",
                })
                return false;
            }
        }
        
        // All passed
        return true;
    }

    handleButtonOnSaveChanges = () => {
        const { userInfo, updatedInfo, selectedVessel, selectedPermit } = this.state;

        updatedInfo.accessible_vessel_idx = selectedVessel;
        updatedInfo.accessible_permit_idx = selectedPermit;
        //console.log(updatedInfo);

        // Validate inputs
        if (!this.fieldValidations(updatedInfo)) return;

        // All passed
        if (JSON.stringify(userInfo) === JSON.stringify(updatedInfo)) {
            // No change, show warning
            this.setState({
                alertType: "Warning",
                alertMessage: "No change found, nothing to save!",
            })
        } else {
            fetch('/api/user/update', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },
                body: JSON.stringify(updatedInfo),
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    alertType: json.Result,
                    alertMessage: json.Message,
                })
                if (json.Result === "Success") {
                    setTimeout(this.props.onBack, 2000);
                }
            })
        }
    }

    handleVesselListChange = (event) => {
        // const { updatedInfo } = this.state;
        const {
          target: { value },
        } = event;
        let rv;
        typeof value === 'string' ? rv = value.split(',') : rv = value;
        //console.log("handleChange: " + rv)
        // updatedInfo.accessible_vessel = rv;     
        // We cannot update 'updatedInfo' itself as it is a const object (like a pointer), but we can alter
        // it content. Same reason, we cannot update 'selectedVessel', so we need to use setState() and
        // this will also trigger React to render() the output.
        this.setState({
            selectedVessel: rv,
        })
    };

    handlePermitListChange = (event) => {
        const {
          target: { value },
        } = event;
        let rv;
        typeof value === 'string' ? rv = value.split(',') : rv = value;
        //console.log("handleChange: " + rv)
        this.setState({
            selectedPermit: rv,
        })
    };

    render() {
        const { userInfo, vesselNames, selectedVessel, permitNames, selectedPermit, userRoles, alertType, alertMessage, dialogOpen } = this.state;

        /* Data not loaded yet */
        if ((userInfo === false) || (vesselNames === false) || (permitNames === false) || (userRoles === false))
            return (<div></div>)

        return (
            <div>
                { /* Alert bar */}
                <AlertBar alertType={alertType} alertMessage={alertMessage} />

                { /* Dialog */}
                <Dialog
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        Discard changes?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog_description">
                            Change(s) detected, discard changes without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button value="discard" onClick={this.handleDialogClose}>Discard</Button>
                        <Button value="keep_editing" onClick={this.handleDialogClose} autoFocus>Keep Editing</Button>
                    </DialogActions>
                </Dialog>

                { /* Form */}
                <div className="EditForm">
                    <Grid container rowSpacing={5} columnSpacing={3}>
                        { /* Large screen row 1 */}
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="username"
                                name="username"
                                label="Login ID"
                                fullWidth
                                variant="standard"
                                defaultValue={userInfo.username}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="displayName"
                                name="displayName"
                                label="User Name"
                                fullWidth
                                variant="standard"
                                defaultValue={userInfo.displayName}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl fullWidth>
                                <InputLabel>User Role</InputLabel>
                                <Select
                                    label="User Role"
                                    defaultValue={userInfo.userRole}
                                    onChange={event => this.handleSelectOnChange(event, "userRole")}
                                >
                                    {userRoles !== false ?
                                        userRoles.map((row) => (
                                            <MenuItem key={row.user_role_id} value={row.user_role_id}>
                                                {row.user_role_name}
                                            </MenuItem>
                                        ))
                                        : null
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl fullWidth>
                                <InputLabel>Account Status</InputLabel>
                                <Select
                                    label="Account Status"
                                    defaultValue={userInfo.status}
                                    onChange={event => this.handleSelectOnChange(event, "status")}
                                >
                                    <MenuItem key="A" value="A">Active</MenuItem>
                                    <MenuItem key="F" value="F">First time login</MenuItem>
                                    <MenuItem key="L" value="L">Locked</MenuItem>
                                    <MenuItem key="S" value="S">Suspended</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <GridBreak />

                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="failCount"
                                name="failCount"
                                label="Failure Count"
                                fullWidth
                                variant="standard"
                                defaultValue={userInfo.failCount}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>

                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                type="password"
                                id="resetPassword"
                                name="resetPassword"
                                label="Reset Password"
                                fullWidth
                                variant="standard"
                                defaultValue=""
                                onBlur={this.handleTextFieldOnBlur}
                                helperText="At least 10 characters with numbers, small letters, captial letters and special characters"
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                label="Confirm Password"
                                fullWidth
                                variant="standard"
                                defaultValue=""
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>

                        <GridBreak />
                        
                        <Grid item xs={12} md={8} lg={6}>
                            <FormControl sx={{ m: 1, width: "95%" }}>
                                <InputLabel id="accessible_vessel_label">Accessible Vessels</InputLabel>
                                <Select
                                    labelId="accessible_vessel_label"
                                    id="accessible_vessel"
                                    multiple
                                    value={selectedVessel}
                                    onChange={this.handleVesselListChange}
                                    input={<OutlinedInput label="Accessible Vessels" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((key) => (
                                                <Chip key={key} label={vesselNames[key]} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                { vesselNames !== false ?
                                    Object.entries(vesselNames).map((row) => {
                                        return (
                                            <MenuItem key={row[0]} value={row[0]}>
                                            <Checkbox checked={selectedVessel.indexOf(row[0]) > -1} />
                                            <ListItemText primary={row[1]} />
                                            </MenuItem>
                                    )}) 
                                    : null
                                }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={8} lg={6}>
                            <FormControl sx={{ m: 1, width: "95%" }}>
                                <InputLabel id="accessible_permit_label">Accessible Permits</InputLabel>
                                <Select
                                    labelId="accessible_permit_label"
                                    id="accessible_permit"
                                    multiple
                                    value={selectedPermit}
                                    onChange={this.handlePermitListChange}
                                    input={<OutlinedInput label="Accessible Permits" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((key) => (
                                                <Chip key={key} label={permitNames[key]} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                { permitNames !== false ?
                                    Object.entries(permitNames).map((row) => {
                                        return (
                                            <MenuItem key={row[0]} value={row[0]}>
                                            <Checkbox checked={selectedPermit.indexOf(row[0]) > -1} />
                                            <ListItemText primary={row[1]} />
                                            </MenuItem>
                                    )}) 
                                    : null
                                }
                                </Select>
                            </FormControl>
                        </Grid>

                        <GridBreak />

                        <Grid item xs={12} md={12} lg={7}>
                            <Button variant="outlined" onClick={this.handleButtonOnBack}>
                                Back
                            </Button>
                            <span>&nbsp;&nbsp;</span>
                            <Button variant="contained" onClick={this.handleButtonOnSaveChanges}>
                                Save Changes
                            </Button>
                        </Grid>

                    </Grid>
                </div>
            </div>
        )
    }
}

export default EditUser;