import React, { useEffect } from 'react';
import { Grid, Button, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import MUIdateTimePickers from '../components/MUIdateTimePickers';
import VesselPicker from '../components/VesselPicker';
import AlertBar from '../components/AlertBar';

import moment from 'moment';
import { MapContainer, TileLayer, Polygon, Tooltip, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import "leaflet-polylinedecorator";

import AuthContext from "../contexts/AuthProvider";

/*
    https://stackoverflow.com/questions/56411133/how-to-use-polylinedacorator-with-react-leaflet
    https://github.com/bbecquet/Leaflet.PolylineDecorator
*/
const trajectoryAttribute = { color: 'red', weight: 1.0 }
const dumpingZoneAttribute = { color: 'brown', weight: 1.0 }
const loadingZoneAttribute = { color: 'green', weight: 1.0 }

var lastPolylineDrawn = null;
var lastDecoratorDrawn = null;

function PolylineWithArrows({ polyline }) {
    const map = useMap();

    useEffect(() => {
        if (!map) return;
        // Clear old
        if (lastPolylineDrawn !== null) {
            map.removeLayer(lastPolylineDrawn);
        }
        if (lastDecoratorDrawn !== null) {
            map.removeLayer(lastDecoratorDrawn);
        }
        // Draw new
        lastPolylineDrawn = L.polyline(polyline, trajectoryAttribute).addTo(map);
        lastDecoratorDrawn = L.polylineDecorator(polyline, {
            patterns: [{
                offset: '25px',
                repeat: '50px',
                symbol: L.Symbol.arrowHead({
                        pixelSize: 5,
                        polygon: false,
                        pathOptions: trajectoryAttribute
                    })
                }]
        }).addTo(map);
    }, [map, polyline]);

    return null;
}


const bargeIcon = L.icon({
    iconUrl: "./markers/barge_icon_yellow.png",
    iconSize: [48, 48], /* original [128, 128] */
    iconAnchor: [35, 47],
    popupAnchor: [0, -10]
});

function FocusVessel(props) {
    const map = useMap()
    map.panTo([props.lat, props.lon]);
}


class Map extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        // default selection
        const today = new Date();
        today.setHours(0, 0, 0);
        const tomorrow = new Date();
        tomorrow.setHours(24, 0, 0);
        this.state = {
            selectedVessel: "",
            startDate: today,
            endDate: tomorrow,
            records: [],
            dataLoad: false,
            trajectory: [],
            vessel_name: "",
            zones: false,
            alertType: "Info",
            alertMessage: false,
            selectZoneType: 'D',
        };
    }

    handleVesselPickerCallback = (childData) => {
        console.log("Records: Selected new vessel > " + childData)
        this.setState({
            selectedVessel: childData,
        })
    }

    handleDateTimePickerStartTimeCallback = (childData) => {
        // console.log("Records > start time > " + childData)
        this.setState({
            startDate: childData,
        })
    }

    handleDateTimePickerEndTimeCallback = (childData) => {
        // console.log("Records > end time > " + childData)
        this.setState({
            endDate: childData,
        })
    }

    handleSelectZoneType = (event) => {
        this.setState({
            selectZoneType: event.target.value,
        })
    }

    handleOnClick = () => {
        const { selectedVessel, startDate, endDate } = this.state;

        // Clear the table first
        this.setState({
            records: [],
            dataLoad: false,
        })

        // Validate selections
        if (selectedVessel.length === 0) {
            this.setState({
                alertType: "Warning",
                alertMessage: "Please select a vessel!",
            })
            return
        }
        // StartDate and endDate have default values
        if (startDate > endDate) {
            this.setState({
                alertType: "Error",
                alertMessage: "Invalid selections: Start date must be earlier than end date!",
            })
            return
        }
        if (((endDate - startDate) / 86400000) > 7) {
            this.setState({
                alertType: "Error",
                alertMessage: "Selection period cannot exceed 7 days!",
            })
            return
        }

        this.loadZone()
        this.loadRecord()
    }

    loadZone = () => {
        const { selectZoneType, selectedVessel, startDate, endDate } = this.state;

        fetch("/api/zone/list", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
            body: JSON.stringify({
                'selectZoneType': selectZoneType,
                'start_date': moment(startDate).format("YYYY-MM-DD"),
                'end_date': moment(endDate).format("YYYY-MM-DD"),
                'unit_id': selectedVessel,
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    zones: json
                })
            })
    }


    loadRecord = () => {
        const { selectedVessel, startDate, endDate } = this.state;

        fetch("/api/vessel/name/" + selectedVessel, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },    
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    vessel_name: json[0].vessel_name,
                })
            })

        fetch("/api/data/records?unit_id=" + selectedVessel +
            "&start_time=" + moment(startDate).format("YYYY-MM-DD HH:mm:ss") +
            "&end_time=" + moment(endDate).format("YYYY-MM-DD HH:mm:ss"), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.length > 0) {
                    // populate result into trajectory
                    let new_trajectory = []
                    json.map((row) => (
                        new_trajectory.push([row.lat, row.lon])
                    ))
                    this.setState({
                        records: json,
                        trajectory: new_trajectory,
                        dataLoad: true,
                        alertType: "Info",
                        alertMessage: false,
                    })
                } else {
                    this.setState({
                        dataLoad: false,
                        alertType: "Info",
                        alertMessage: "No data found!",
                    })
                }
            })
    }

    render() {
        const { startDate, endDate, records, dataLoad, trajectory, vessel_name, zones, alertType, alertMessage } = this.state;
        // console.log(trajectory)
        // console.log(zones)

        return (
            <div>
                { /* Selections */}
                <div className="RecordsOptions">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={2}>
                            <VesselPicker parentCallback={this.handleVesselPickerCallback} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <MUIdateTimePickers initial={startDate} label="Start" parentCallback={this.handleDateTimePickerStartTimeCallback} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <MUIdateTimePickers initial={endDate} label="End" parentCallback={this.handleDateTimePickerEndTimeCallback} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={2}>
                            <FormControl sx={{ minWidth: 150, marginLeft: 0 }}>
                                <InputLabel>Zone</InputLabel>
                                <Select defaultValue='D' onChange={this.handleSelectZoneType} >
                                    <MenuItem key='A' value='A'>All</MenuItem>
                                    <MenuItem key='D' value='D'>Dumping only</MenuItem>
                                    <MenuItem key='P' value='P'>Under permits</MenuItem>
                                </Select>
                            </FormControl>
                    </Grid>
                        <Grid item xs={12} md={4} lg={2}>
                            <Button variant='contained'
                                onClick={this.handleOnClick}
                            >Search</Button>
                        </Grid>
                    </Grid>
                </div>

                { /* Alert bar */}
                <AlertBar alertType={alertType} alertMessage={alertMessage} />

                { /* Selected Results */}
                <div className='MapView' style={{ marginTop: 10 }}>
                    <Paper elevation={3}>
                        <MapContainer
                            center={[22.2, 113.6]}
                            zoom={10}
                            scrollWheelZoom={false}
                            style={{ height: "70vh", width: "100%" }}
                        >
                            <TileLayer
                                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenSeaMap</a> contributors'
                                opacity={0.75}
                            />
                            <TileLayer
                                url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
                                opacity={0.25}
                            />
                            {
                                dataLoad ?
                                    <React.Fragment>
                                        <PolylineWithArrows polyline={trajectory} />
                                        <Marker position={[records[records.length - 1].lat, records[records.length - 1].lon]} icon={bargeIcon}>
                                            <Popup>
                                                Vessel name: {vessel_name}<br />
                                                Time: {moment(records[records.length - 1].hk_time).format("YYYY-MM-DD HH:mm:ss")}<br />
                                                Speed: {Number(records[records.length - 1].knots).toFixed(1)} knots<br />
                                                Draught: {Number(records[records.length - 1].draught_meter).toFixed(1)} meters
                                            </Popup>
                                        </Marker>
                                        <FocusVessel lat={records[records.length - 1].lat} lon={records[records.length - 1].lon} />
                                    </React.Fragment>
                                    : null
                            }
                            {
                                zones ?
                                    zones.map((Z) => (
                                        <Polygon key={Z.zone_name} pathOptions={Z.zone_type === 'D' ? dumpingZoneAttribute : loadingZoneAttribute} positions={Z.vertex}>
                                            <Tooltip sticky>{Z.zone_name}</Tooltip>
                                        </Polygon>
                                    ))
                                    : null
                            }
                        </MapContainer>
                    </Paper>
                </div>
            </div>
        );
    }
}

export default Map;