import React from 'react';
import { Grid, Button } from '@mui/material';
import MUIdateTimePickers from '../components/MUIdateTimePickers';
import VesselPicker from '../components/VesselPicker';
import AlertBar from '../components/AlertBar';
import moment from 'moment';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AuthContext from "../contexts/AuthProvider";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#1976D2',         // theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const HighlightedTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#ff3333',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

class Activities extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        // default selection
        const today = new Date();
        today.setHours(0, 0, 0);
        const tomorrow = new Date();
        tomorrow.setHours(24, 0, 0);
        this.state = {
            selectedVessel: "",
            startDate: today,
            endDate: tomorrow,
            records: [],
            dataLoad: false,
            alertMessage: false,
            alertType: "info",
            page: 0,
            rowsPerPage: 25,
        };
    }

    handleVesselPickerCallback = (childData) => {
        //console.log("Records: Selected new vessel > " + childData)
        this.setState({
            selectedVessel: childData,
        })
    }

    handleDateTimePickerStartTimeCallback = (childData) => {
        // console.log("Records > start time > " + childData)
        this.setState({
            startDate: childData,
        })
    }

    handleDateTimePickerEndTimeCallback = (childData) => {
        // console.log("Records > end time > " + childData)
        this.setState({
            endDate: childData,
        })
    }

    handleOnClick = () => {
        const { startDate, endDate } = this.state;

        // Clear table
        this.setState({
            records: [],
            dataLoad: false,
        })

        // Validate selections
        // StartDate and endDate have default values
        if (startDate > endDate) {
            this.setState({
                alertType: "Error",
                alertMessage: "Invalid selections: Start date must be earlier than end date!",
            })
            return
        }
        if (((endDate - startDate) / 86400000) > 90) {
            this.setState({
                alertType: "Error",
                alertMessage: "Selection period cannot exceed 90 days!",
            })
            return
        }

        this.loadRecord()
    }


    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };


    loadRecord = () => {
        const { selectedVessel, startDate, endDate } = this.state;
        fetch("/api/data/activities?unit_id=" + selectedVessel +
            "&start_time=" + moment(startDate).format("YYYY-MM-DD HH:mm:ss") +
            "&end_time=" + moment(endDate).format("YYYY-MM-DD HH:mm:ss"), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.length > 0) {
                    this.setState({
                        records: json,
                        dataLoad: true,
                        alertMessage: false,
                    })
                } else {
                    this.setState({
                        dataLoad: false,
                        alertType: "Info",
                        alertMessage: "No data found!",
                    })
                }
            })
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    emptyRows() {
        return this.page > 0 ? Math.max(0, (1 + this.page) * this.rowsPerPage - this.records.length) : 0;
    }

    render() {
        const { startDate, endDate, records, dataLoad, alertMessage, alertType, page, rowsPerPage } = this.state;

        const activity_type_desc = {}
        activity_type_desc['D'] = 'Dumping';
        activity_type_desc['L'] = 'Loading';
        activity_type_desc['U'] = 'Dumping outside zone';
        activity_type_desc['M'] = 'Missing data';
    
        return (
            <div>
                { /* Selections */}
                <div className="RecordsOptions">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <VesselPicker allowAll={true} parentCallback={this.handleVesselPickerCallback} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <MUIdateTimePickers initial={startDate} label="Start" parentCallback={this.handleDateTimePickerStartTimeCallback} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <MUIdateTimePickers initial={endDate} label="End" parentCallback={this.handleDateTimePickerEndTimeCallback} />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button variant='contained'
                                onClick={this.handleOnClick}
                            >Search</Button>
                        </Grid>
                    </Grid>
                </div>

                { /* Alert bar */ }
                <AlertBar alertType={alertType} alertMessage={alertMessage} />

                { /* Selected Results */}
                <div className="RecordsContent" style={{ marginTop: 10 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            {
                                dataLoad ? (
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Unit ID</StyledTableCell>
                                            <StyledTableCell align="center">Vessel Name</StyledTableCell>
                                            <StyledTableCell align="center">Activity</StyledTableCell>
                                            <StyledTableCell align="center">Start Time</StyledTableCell>
                                            <StyledTableCell align="center">End Time</StyledTableCell>
                                            <StyledTableCell align="center">Draught Change(m)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                )
                                    : null
                            }
                            <TableBody>
                                { dataLoad ?
                                        records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <StyledTableRow key={row.idx} >
                                                <StyledTableCell align="center">{row.unit_id}</StyledTableCell>
                                                <StyledTableCell align="center">{row.vessel_name}</StyledTableCell>
                                                { row.activity_type === 'U' ?
                                                    <HighlightedTableCell align="center">{activity_type_desc[row.activity_type]}</HighlightedTableCell>
                                                  :
                                                    <StyledTableCell align="center">{activity_type_desc[row.activity_type]}</StyledTableCell>
                                                }
                                                <StyledTableCell align="center">{moment(row.start_time).format("YYYY-MM-DD HH:mm:ss")}</StyledTableCell>
                                                <StyledTableCell align="center">{moment(row.end_time).format("YYYY-MM-DD HH:mm:ss")}</StyledTableCell>
                                                <StyledTableCell align="center">{Number(row.start_draught).toFixed(2)} -&gt; {Number(row.end_draught).toFixed(2)}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                        : null
                                }
                                {this.emptyRows() > 0 && (
                                    <TableRow
                                        style={{
                                            height: 53 * this.emptyRows(),
                                        }}
                                    >
                                        <TableCell colSpan={7} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { dataLoad ?
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={records.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                      : null
                    }
                </div>
            </div>
        );
    }
}

export default Activities;
