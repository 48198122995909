import React from "react";
import {
    Grid, Button, FormControl, InputLabel, Select, MenuItem, TextField,
    Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions,
    OutlinedInput, Checkbox, ListItemText, Box, Chip
} from '@mui/material';
import AlertBar from '../components/AlertBar';
import styled from "styled-components";

import AuthContext from "../contexts/AuthProvider";

export const GridBreak = styled.div`
    width: 100%
`;


class NewUser extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props)
        this.state = {
            userInfo: false,
            alertType: "Info",
            alertMessage: "Please make sure the information is correct before update!",
            dialogOpen: false,
            userRoles: false,
            vesselNames: false,
            permitNames: false,
            selectedVessel: [],
            selectedPermit: [],
            existingUserList: false,
        }
    }

    componentDidMount() {
        const { userInfo, vesselNames, permitNames, userRoles, existingUserList } = this.state;

        if (vesselNames === false) {
            fetch("/api/vessel/names", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                // convert return json to dictionary [idx]->name
                var vessels = {};
                json.map((row) => (
                    vessels[row.idx] = row.vessel_name
                ))
                this.setState({
                    vesselNames: vessels,
                })
            })
        }

        if (permitNames === false) {
            fetch("/api/permit/names", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                // convert return json to dictionary [idx]->name
                var permits = {};
                json.map((row) => (
                    permits[row.idx] = row.permit_number
                ))
                this.setState({
                    permitNames: permits,
                })
            })
        }

        if (userRoles === false) {
            fetch("/api/user/roles", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    userRoles: json,
                })
            })
        }

        if (existingUserList === false) {
            fetch("/api/user/names", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    existingUserList: json,
                })
            })
        }

        if (userInfo === false) {
            var info = {}
            info['username'] = "";
            info['displayName'] = "";
            info['password'] = "";
            info['status'] = 'F';
            info['failCount'] = 0;
            info['userRole'] = 0
            info['accessible_vessel_idx'] = "";
            info['acesssible_permit_idx'] = "";
            this.setState({
                userInfo: info,
            })
        }
    }

    handleTextFieldOnBlur = (event) => {
        let { userInfo } = this.state;
        userInfo[event.target.id] = event.target.value;
    }

    handleSelectOnChange = (event, field_id) => {
        let { userInfo } = this.state;
        userInfo[field_id] = event.target.value;
    }

    handleButtonOnBack = () => {
        const { userInfo } = this.state;
        
        if ((userInfo.username === "") && (userInfo.displayName === "")) {
            // No change, go back
            this.props.onBack();
        } else {
            this.setState({
                dialogOpen: true,
            })
        }
    }

    handleDialogClose = (event) => {
        this.setState({
            dialogOpen: false,
        })
        if (event.target.value === "discard") {
            this.props.onBack();
        }
    }

    // ====================================
    // Validations
    fieldValidations(info) {
        const { existingUserList } = this.state;

        const regExNoSpChar = new RegExp(/^[^*<>!%'"+=;^$]*$/);         // Not contains special characters
        const password_regex=  /^(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{10,50}$/;
        const regExPwd = new RegExp(password_regex);
        var validationFailed = false;

        // Check if username valid and not duplicated
        if (!regExNoSpChar.test(info.username) || info.username === undefined || info.username.length > 15) {
            this.setState({
                alertType: "Error",
                alertMessage: "Login ID should not contain special character, cannot be empty and maximum 15 characters!",
            })
            return false;
        }
        existingUserList.forEach(existing => {
            if (existing.username === info.username) {
                console.log("Duplicated Login ID");
                this.setState({
                    alertType: "Error",
                    alertMessage: `Login ID ${info.username} has been used, please use an unique login ID!`,
                })
                validationFailed = true;
                return;
            }
        });
        if (validationFailed) return false;

        // Check if display name valid and maximum 45 characters
        if (!regExNoSpChar.test(info.displayName) || info.displayName === undefined || info.displayName.length > 45) {
            this.setState({
                alertType: "Error",
                alertMessage: "User name should not contain special character, cannot be empty and maximum 45 characters!",
            })
            return false;
        }

        if (!regExPwd.test(info.password) || info.password.length === 0) {
            this.setState({
                alertType: "Error",
                alertMessage: "Password should at least 8 characters, with 1 uppercase, 1 lowercase, 1 digit and 1 special character!",
            })
            return false;
        }

        if (info.password !== info.confirmPassword) {
            this.setState({
                alertType: "Error",
                alertMessage: "Password unmatch!",
            })
            return false;
        }

        // All passed
        return true;
    }


    handleButtonOnSaveChanges = () => {
        const { userInfo, selectedVessel, selectedPermit } = this.state;

        userInfo.accessible_vessel_idx = selectedVessel;
        userInfo.accessible_permit_idx = selectedPermit;
        
        // Validate inputs
        if (!this.fieldValidations(userInfo)) return;

        // All passed
        fetch('/api/user/add', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
            body: JSON.stringify(userInfo),
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    alertType: json.Result,
                    alertMessage: json.Message,
                })
                if (json.Result === "Success") {
                    setTimeout(this.props.onBack, 2000);
                }
            })
    }

    handleVesselListChange = (event) => {
        // const { updatedInfo } = this.state;
        const {
          target: { value },
        } = event;
        let rv;
        typeof value === 'string' ? rv = value.split(',') : rv = value;
        //console.log("handleChange: " + rv)
        // updatedInfo.accessible_vessel = rv;     
        // We cannot update 'updatedInfo' itself as it is a const object (like a pointer), but we can alter
        // it content. Same reason, we cannot update 'selectedVessel', so we need to use setState() and
        // this will also trigger React to render() the output.
        this.setState({
            selectedVessel: rv,
        })
    };

    handlePermitListChange = (event) => {
        const {
          target: { value },
        } = event;
        let rv;
        typeof value === 'string' ? rv = value.split(',') : rv = value;
        //console.log("handleChange: " + rv)
        this.setState({
            selectedPermit: rv,
        })
    };

    render() {
        const { userInfo, userRoles, vesselNames, selectedVessel, permitNames, selectedPermit, alertType, alertMessage, dialogOpen } = this.state;

        /* Data not loaded yet */
        if ((userInfo === false) || (userRoles === false) || (vesselNames === false) || (permitNames === false))
            return (<div></div>)

        return (
            <div>
                { /* Alert bar */}
                <AlertBar alertType={alertType} alertMessage={alertMessage} />

                { /* Dialog */}
                <Dialog
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        Discard changes?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog_description">
                            Change(s) detected, discard changes without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button value="discard" onClick={this.handleDialogClose}>Discard</Button>
                        <Button value="keep_editing" onClick={this.handleDialogClose} autoFocus>Keep Editing</Button>
                    </DialogActions>
                </Dialog>

                { /* Form */}
                <div className="EditForm">
                    <Grid container rowSpacing={5} columnSpacing={3}>
                        { /* Large screen row 1 */}
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="username"
                                name="username"
                                label="Login ID"
                                fullWidth
                                variant="standard"
                                defaultValue={userInfo.username}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="displayName"
                                name="displayName"
                                label="User Name"
                                fullWidth
                                variant="standard"
                                defaultValue={userInfo.displayName}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl fullWidth>
                                <InputLabel>User Role</InputLabel>
                                <Select
                                    label="User Role"
                                    defaultValue={userInfo.userRole}
                                    onChange={event => this.handleSelectOnChange(event, "userRole")}
                                >
                                    {userRoles !== false ?
                                        userRoles.map((row) => (
                                            <MenuItem key={row.user_role_id} value={parseInt(row.user_role_id)}>
                                                {row.user_role_name}
                                            </MenuItem>
                                        ))
                                        : null
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl fullWidth>
                                <InputLabel>Account Status</InputLabel>
                                <Select
                                    label="Account Status"
                                    defaultValue={userInfo.status}
                                    onChange={event => this.handleSelectOnChange(event, "status")}
                                >
                                    <MenuItem key="A" value="A">Active</MenuItem>
                                    <MenuItem key="F" value="F">First time login</MenuItem>
                                    <MenuItem key="L" value="L">Locked</MenuItem>
                                    <MenuItem key="S" value="S">Suspended</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <GridBreak />

                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="failCount"
                                name="failCount"
                                label="Failure Count"
                                fullWidth
                                variant="standard"
                                defaultValue={userInfo.failCount}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>

                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                type="password"
                                id="password"
                                name="password"
                                label="Password"
                                fullWidth
                                variant="standard"
                                defaultValue=""
                                onBlur={this.handleTextFieldOnBlur}
                                helperText="At least 10 characters with numbers, small letters, captial letters and special characters"
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                label="Confirm Password"
                                fullWidth
                                variant="standard"
                                defaultValue=""
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>

                        <GridBreak />
                        
                        <Grid item xs={12} md={8} lg={6}>
                            <FormControl sx={{ m: 1, width: "95%" }}>
                                <InputLabel id="accessible_vessel_label">Accessible Vessels</InputLabel>
                                <Select
                                    labelId="accessible_vessel_label"
                                    id="accessible_vessel"
                                    multiple
                                    value={selectedVessel}
                                    onChange={this.handleVesselListChange}
                                    input={<OutlinedInput label="Accessible Vessels" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((key) => (
                                                <Chip key={key} label={vesselNames[key]} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                { vesselNames !== false ?
                                    Object.entries(vesselNames).map((row) => {
                                        return (
                                            <MenuItem key={row[0]} value={row[0]}>
                                            <Checkbox checked={selectedVessel.indexOf(row[0]) > -1} />
                                            <ListItemText primary={row[1]} />
                                            </MenuItem>
                                    )}) 
                                    : null
                                }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={8} lg={6}>
                            <FormControl sx={{ m: 1, width: "95%" }}>
                                <InputLabel id="accessible_permit_label">Accessible Permits</InputLabel>
                                <Select
                                    labelId="accessible_permit_label"
                                    id="accessible_permit"
                                    multiple
                                    value={selectedPermit}
                                    onChange={this.handlePermitListChange}
                                    input={<OutlinedInput label="Accessible Permits" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((key) => (
                                                <Chip key={key} label={permitNames[key]} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                { permitNames !== false ?
                                    Object.entries(permitNames).map((row) => {
                                        return (
                                            <MenuItem key={row[0]} value={row[0]}>
                                            <Checkbox checked={selectedPermit.indexOf(row[0]) > -1} />
                                            <ListItemText primary={row[1]} />
                                            </MenuItem>
                                    )}) 
                                    : null
                                }
                                </Select>
                            </FormControl>
                        </Grid>

                        <GridBreak />

                        <Grid item xs={12} md={12} lg={7}>
                            <Button variant="outlined" onClick={this.handleButtonOnBack}>
                                Cancel
                            </Button>
                            <span>&nbsp;&nbsp;</span>
                            <Button variant="contained" onClick={this.handleButtonOnSaveChanges}>
                                Create User
                            </Button>
                        </Grid>

                    </Grid>
                </div>
            </div>
        )
    }
}

export default NewUser;