import React from "react";
import {
    Grid, Button, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Checkbox
    , Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions
} from '@mui/material';
import MUIdateTimePickers from '../components/MUIdateTimePickers';
import AlertBar from '../components/AlertBar';
import styled from "styled-components";

import AuthContext from "../contexts/AuthProvider";
import {ROLES} from "../constants";

export const GridBreak = styled.div`
    width: 100%
`;

class EditVessel extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props)
        this.state = {
            vessel_idx: props.vessel_idx,
            vesselInfo: false,
            updatedInfo: false,     /* a copy of existing info for storing and checking any change */
            alertType: "Info",
            alertMessage: "Please make sure the information is correct before update!",
            dialogOpen: false,
            existingVesselList: false,
        }
    }

    componentDidMount() {
        const { vessel_idx, vesselInfo, existingVesselList } = this.state;

        if (vesselInfo === false) {
            fetch("/api/vessel/detail/" + vessel_idx, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                //console.log(json)
                if (json.length > 0) {
                    this.setState({
                        vesselInfo: json[0],
                        updatedInfo: { ...json[0] },
                    })
                }
            })
        }

        if (existingVesselList === false) {
            fetch("/api/vessel/names", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                //console.log(json)
                if (json.length > 0) {
                    this.setState({
                        existingVesselList: json,
                    })
                }
            })
        }

    }

    handleTextFieldOnBlur = (event) => {
        const { updatedInfo } = this.state;
        updatedInfo[event.target.id] = event.target.value;
    }

    handleCheckboxOnChange = (event) => {
        const { updatedInfo } = this.state;
        updatedInfo[event.target.id] = event.target.checked ? 'Y' : 'N';
    }

    handleSelectOnChange = (event, field_id) => {
        const { updatedInfo } = this.state;
        //console.log(field_id);
        //console.log(event.target.value);
        updatedInfo[field_id] = event.target.value;
        //this.setState({
        //    updatedInfo: updatedInfo,
        //})
    }

    handleDatePickerCallback = (value, field_id) => {
        const { updatedInfo } = this.state;
        updatedInfo[field_id] = new Date(value).toISOString();
    }

    handleButtonOnBack = () => {
        const { vesselInfo, updatedInfo } = this.state;
        //console.log(updatedInfo);
        if (JSON.stringify(vesselInfo) === JSON.stringify(updatedInfo)) {
            // No change, go back
            this.props.onBack();
        } else {
            this.setState({
                dialogOpen: true,
            })
        }
    }

    handleDialogClose = (event) => {
        this.setState({
            dialogOpen: false,
        })
        if (event.target.value === "discard") {
            this.props.onBack();
        }
    }

    // ====================================
    // Validations
    fieldValidations(info) {
        const { existingVesselList } = this.state;

        const regExUnitID = new RegExp(/^[1-9]\d{3}$/);                 // 4-digits, starts with 1-9
        const regExNoSpChar = new RegExp(/^[^*<>!%'"+=;^$]*$/);         // Not contains special characters
        const regExGenString = new RegExp(/^[0-9A-Z-]*$/);              // Digits, hyphen and Upper letters
        const regExSIM = new RegExp(/^[1-9]\d{7}$/);                    // 8-digits, starts with 1-9
        const regExIP = new RegExp(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/);  // IP address (simple)
        const regExVer = new RegExp(/^[1-9][0-9.]*$/);                  // Digits and dot, starts with 1-9
        var validationFailed = false;

        // Check if unit_id is 4-digits number and not duplicated
        if (!regExUnitID.test(info.unit_id)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Unit ID must be a 4-digits number!",
            })
            return false;
        }
        existingVesselList.forEach(existing => {
            if (parseInt(existing.unit_id) === parseInt(info.unit_id)) {
                if (existing.idx !== info.idx) {
                    console.log("Duplicated Unit ID");
                    this.setState({
                        alertType: "Error",
                        alertMessage: `Unit ID ${info.unit_id} has been used by vessel '${existing.original_name}' !`,
                    })
                    validationFailed = true;
                    return;
                }
            }
        });
        if (validationFailed) return false;

        // Check vessel name should not contain special characters, less than 45 characters, not duplicated
        if (!regExNoSpChar.test(info.vessel_name) || (info.vessel_name.length === 0) || (info.vessel_name.length > 45)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Vessel name (Eng) should not contain special character, cannot be empty and maximum 45 characters!",
            })
            return false;
        }
        existingVesselList.forEach(existing => {
            if (existing.original_name === info.vessel_name) {
                if (existing.idx !== info.idx) {
                        this.setState({
                        alertType: "Error",
                        alertMessage: `Vessel name (Eng) '${info.vessel_name}' has been used by Unit ${existing.unit_id} !`,
                    })
                    validationFailed = true;
                    return;
                }
            }
        });
        if (validationFailed) return false;

        // Check vessel name Chinese should not contain special characters
        if (!regExNoSpChar.test(info.vessel_name_chi) || (info.vessel_name_chi.length > 45)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Vessel name (Chi) should not contain special character and maximum 45 characters!",
            })
            return false;
        }

        // Check vessel number
        if (!regExGenString.test(info.vessel_number) || (info.vessel_number.length === 0) || (info.vessel_number.length > 20)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Vessel number only allows digits, hyphen and uppercase letters, cannot be empty and maximum 20 characters!",
            })
            return false;
        }

        // Check FEMU serial number
        if (!regExGenString.test(info.serial_no) || (info.serial_no.length === 0) || (info.serial_no.length > 20)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Serial no. only allows digits, hyphen and uppercase letters, cannot be empty and maximum 20 characters!",
            })
            return false;
        }

        // Check Camera serial number
        if (!regExGenString.test(info.cam_serial_no) || (info.cam_serial_no.length === 0) || (info.cam_serial_no.length > 20)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Camera serial no. only allows digits, hyphen and uppercase letters, cannot be empty and maximum 20 characters!",
            })
            return false;
        }

        // Check MRT number
        if (!regExSIM.test(info.MRT)) {
            this.setState({
                alertType: "Error",
                alertMessage: "SIM Card No. should be 8-digits only!",
            })
            return false;
        }

        // Check SIM APN should not contain special characters
        if (!regExNoSpChar.test(info.SIM_APN) || (info.SIM_APN.length === 0) || (info.SIM_APN.length > 20)) {
            this.setState({
                alertType: "Error",
                alertMessage: "SIM Card APN should not contain special character, cannot be empty and maximum 20 characters!",
            })
            return false;
        }

        // Check VPN IP address
        if (!regExIP.test(info.vpn_IP)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Invalid VPN IP address syntax!",
            })
            return false;
        }

        // Check Firmware version
        if (!regExVer.test(info.firmware_version)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Invalid firmware version syntax!",
            })
            return false;
        }

        // Check EPD key should not contain special characters and max. 10 characters
        if (!regExNoSpChar.test(info.epd_key) || (info.epd_key.length === 0) || (info.epd_key.length > 10)) {
            this.setState({
                alertType: "Error",
                alertMessage: "EPD Key should not contain special character, cannot be empty and maximum 10 characters!",
            })
            return false;
        }

        // Check FEMU key should not contain special characters and max. 10 characters
        if (!regExNoSpChar.test(info.femu_key) || (info.femu_key.length === 0) || (info.femu_key.length > 10)) {
            this.setState({
                alertType: "Error",
                alertMessage: "FEMU Key should not contain special character, cannot be empty and maximum 10 characters!",
            })
            return false;
        }

        if (info.install_date === "") {
            this.setState({
                alertType: "Error",
                alertMessage: "Please enter install date!",
            })
            return false;
        }

        if (info.effective_from === "") {
            this.setState({
                alertType: "Error",
                alertMessage: "Please enter effective from date!",
            })
            return false;
        }

        // All passed
        return true;
    }

    handleButtonOnSaveChanges = () => {
        const { vesselInfo, updatedInfo } = this.state;

        // Validate inputs
        if (!this.fieldValidations(updatedInfo)) return;

        // All passed
        if (JSON.stringify(vesselInfo) === JSON.stringify(updatedInfo)) {
            // No change, show warning
            this.setState({
                alertType: "Warning",
                alertMessage: "No change found, nothing to save!",
            })
        } else {
            fetch('/api/vessel/update', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },
                body: JSON.stringify(updatedInfo),
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    alertType: json.Result,
                    alertMessage: json.Message,
                })
                if (json.Result === "Success") {
                    setTimeout(this.props.onBack, 2000);
                }
            })
        }

    }


    render() {
        const { vesselInfo, alertType, alertMessage, dialogOpen } = this.state;

        /* Data not loaded yet */
        if (vesselInfo === false) return (<div></div>)

        const isAdmin = this.context.hasRole([ROLES.ADMIN]);

        return (
            <div>
                { /* Alert bar */
                  isAdmin ?
                    <AlertBar alertType={alertType} alertMessage={alertMessage} />
                  : null
                }
                
                { /* Dialog */}
                <Dialog
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        Discard changes?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog_description">
                            Change(s) detected, discard changes without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button value="discard" onClick={this.handleDialogClose}>Discard</Button>
                        <Button value="keep_editing" onClick={this.handleDialogClose} autoFocus>Keep Editing</Button>
                    </DialogActions>
                </Dialog>

                { /* Form */}
                <div className="EditForm">
                    <Grid container rowSpacing={5} columnSpacing={3}>
                        { /* Large screen row 1 */}
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="unit_id"
                                name="unitID"
                                label="FEMU Unit ID"
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.unit_id}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="vessel_name"
                                name="vesselName"
                                label="Vessel Name (Eng)"
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.vessel_name}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                id="vessel_name_chi"
                                name="vesselNameChi"
                                label="Vessel Name (Chi)"
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.vessel_name_chi}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="vessel_number"
                                name="vesselNumber"
                                label="Vessel No."
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.vessel_number}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>

                        { /* Large screen row 2 */}
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="serial_no"
                                name="serialNo"
                                label="Serial No."
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.serial_no}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="MRT"
                                name="MRT"
                                label="SIM Card No."
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.MRT}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="SIM_APN"
                                name="simAPN"
                                label="SIM Card APN"
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.SIM_APN}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControlLabel
                                control={<Checkbox
                                    id="our_SIM"
                                    name="ourSIM"
                                    defaultChecked={vesselInfo.our_SIM === 'Y'}
                                    onChange={this.handleCheckboxOnChange}
                                />}
                                label="We provide SIM card"
                            />
                        </Grid>


                        { /* Large screen row 3 */}
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="cam_serial_no"
                                name="camSerialNo"
                                label="Camera Serial No."
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.cam_serial_no}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="vpn_IP"
                                name="vpnIP"
                                label="VPN IP Address"
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.vpn_IP}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl fullWidth>
                                <InputLabel>Router Model</InputLabel>
                                <Select
                                    label="Router Model"
                                    defaultValue={vesselInfo.router_model}
                                    onChange={event => this.handleSelectOnChange(event, "router_model")}
                                >
                                    <MenuItem key="E224" value="E224">E224</MenuItem>
                                    <MenuItem key="E228" value="E228">E228</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="firmware_version"
                                name="firmwareVersion"
                                label="Firmware Version"
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.firmware_version}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>

                        <GridBreak />

                        { /* Large screen row 4 */}
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="epd_key"
                                name="epdKey"
                                label="EPD Key"
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.epd_key}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField
                                required
                                id="femu_key"
                                name="femuKey"
                                label="FEMU Key"
                                fullWidth
                                variant="standard"
                                defaultValue={vesselInfo.femu_key}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            { /* Effective Date */}
                            <MUIdateTimePickers
                                id="effective_from"
                                label="Keys Effective from"
                                dateOnly={false}
                                initial={vesselInfo.effective_from}
                                parentCallback={newValue => this.handleDatePickerCallback(newValue, "effective_from")}
                            />
                        </Grid>

                        <GridBreak />

                        { /* Large screen row 5 */}
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    label="Status"
                                    defaultValue={vesselInfo.record_status}
                                    onChange={event => this.handleSelectOnChange(event, "record_status")}
                                >
                                    <MenuItem key="A" value="A">Active</MenuItem>
                                    <MenuItem key="S" value="S">Suspended</MenuItem>
                                    <MenuItem key="N" value="N">Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            { /* Install Date */}
                            <MUIdateTimePickers
                                id="install_date"
                                label="Install Date"
                                dateOnly={true}
                                initial={vesselInfo.install_date}
                                parentCallback={newValue => this.handleDatePickerCallback(newValue, "install_date")}
                            />
                        </Grid>

                        <GridBreak />

                        <Grid item xs={12} md={4} lg={3}>
                            <Button variant="outlined" onClick={this.handleButtonOnBack}>
                                Back
                            </Button>
                            <span>&nbsp;&nbsp;</span>
                            { isAdmin ?
                                <Button variant="contained" onClick={this.handleButtonOnSaveChanges}>
                                    Save Changes
                                </Button>
                              : null
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default EditVessel;