import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from "react";
import useAuth from '../hooks/useAuth';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1976D2',         // theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const HighlightedTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: '#ff0000',
    },
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function Summary() {
    const [myData, setMyData] = useState({})
    const [dataLoad, setDataLoad] = useState(0)
    const auth = useAuth();

    useEffect(() => {
        fetch("/api/admin/summary", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + auth.accessToken,
            },
        })
        .then((res) => res.json())
        .then((json) => {
            setMyData(json)
            setDataLoad(true)
        })
        .catch((error) => {
            console.log("Error: " + error);
        })
    }, [auth.accessToken]);

    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
            <TableRow>
                <StyledTableCell align="center">Unit&nbsp;ID</StyledTableCell>
                <StyledTableCell align="center">Vessel&nbsp;name</StyledTableCell>
                <StyledTableCell align="center">Last&nbsp;record&nbsp;(HKT)</StyledTableCell>
                <StyledTableCell align="center">Last status&nbsp;(HKT)</StyledTableCell>
                <StyledTableCell align="center">Battery&nbsp;(V)</StyledTableCell>
                <StyledTableCell align="center">Last photo&nbsp;(HKT)</StyledTableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
                dataLoad ?
                    myData.map((row) => (
                        <StyledTableRow key={row.unit_id} >
                            <StyledTableCell component="th" scope="row">{row.unit_id}</StyledTableCell>
                            <StyledTableCell align="left">{row.vessel_name}</StyledTableCell>
                            {   /* 5 minutes grace period */
                                row.E1 > 300 ? (
                                    <HighlightedTableCell align="center">{new Date(row.record_hkt).toLocaleString("en-GB", {"dateStyle":"medium","timeStyle":"short"})}</HighlightedTableCell>
                                ): (
                                    <StyledTableCell align="center">{new Date(row.record_hkt).toLocaleString("en-GB", {"dateStyle":"medium","timeStyle":"short"})}</StyledTableCell>
                                )
                            }
                            {   /* 60 minutes grace period */
                                row.E2 > 3600 ? (
                                    <HighlightedTableCell align="center">{new Date(row.status_hkt).toLocaleString("en-GB", {"dateStyle":"medium","timeStyle":"short"})}</HighlightedTableCell>
                                ): (
                                    <StyledTableCell align="center">{new Date(row.status_hkt).toLocaleString("en-GB", {"dateStyle":"medium","timeStyle":"short"})}</StyledTableCell>
                                )
                            }
                            {   /* 18 Volt */
                                Number(row.battery_voltage) < 18 ? (
                                    <HighlightedTableCell align="center">{Number(row.battery_voltage).toFixed(1)}</HighlightedTableCell>
                                ): (
                                    <StyledTableCell align="center">{Number(row.battery_voltage).toFixed(1)}</StyledTableCell>
                                )
                            }
                            {   /* 60 minutes grace period */
                                row.E3 > 3600 ? (
                                    <HighlightedTableCell align="center">{new Date(row.photo_time).toLocaleString("en-GB", {"dateStyle":"medium","timeStyle":"short"})}</HighlightedTableCell>
                                ): (
                                    <StyledTableCell align="center">{new Date(row.photo_time).toLocaleString("en-GB", {"dateStyle":"medium","timeStyle":"short"})}</StyledTableCell>
                                )
                            }
                        </StyledTableRow>
                    ))
                    : null
            }
            </TableBody>
        </Table>
        </TableContainer>

    );
}
