import * as React from 'react';
import ZoneList from './ZoneList';
import EditZone from './EditZone';
import NewZone from './NewZone';

class Zones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curPage: 0,
            selectedZone: false,
        }
    }

    handleZoneListOnClickCallback = (zone_idx) => {
        //console.log("Vessels: handleZoneListOnClickCallback: zone = " + zone_idx)
        this.setState({
            curPage: 1,
            selectedZone: zone_idx,
        })
    }

    onAddZone = (event) => {
        event.preventDefault();
        console.log("Add new zone")
        this.setState({
            curPage: 2,
        })
    }

    onBack = () => {
        this.setState({
            curPage: 0,
        })
    }

    getPage = () => {
        const { curPage, selectedZone } = this.state;

        switch (curPage) {
            case 0:
                return <ZoneList parentCallback={this.handleZoneListOnClickCallback} onAddZone={this.onAddZone} />;
            case 1:
                return <EditZone zone_idx={selectedZone} onBack={this.onBack} />;
            case 2:
                return <NewZone onBack={this.onBack} />
            default:
                throw new Error("Unknown page");
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.getPage()}
            </React.Fragment>
        )
    }
}

export default Zones;