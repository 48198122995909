import React from 'react';
import { Grid, Button, Paper, Typography } from '@mui/material';
import moment from 'moment';

import AuthContext from "../contexts/AuthProvider";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';
import { BarChart, Bar } from 'recharts';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#D23319',         
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
/*
const HighlightedTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#ff3333',
    },
}));
*/

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));




class Dashboard extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.vessel_count = false;
        this.failure_record = false;
        this.failure_photo = false;
        this.low_battery = false;
        this.record_count = false;
        this.photo_count = false;
        this.dumping_outside = false;

        this.state = {
            lastUpdateTime: false,
            refresh: 1,
        }
    }

    loadData() {
        var loadingCount = 7;

        fetch("/api/admin/dashboard?dataType=vessel_count", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
        })
        .then((res) => res.json())
        .then((json) => {
            this.vessel_count = json;
            loadingCount -= 1;
            if (loadingCount === 0)
                this.setState({
                    lastUpdateTime: new Date(),
                })
        });

        fetch("/api/admin/dashboard?dataType=failure_record", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
        })
        .then((res) => res.json())
        .then((json) => {
            this.failure_record = json;
            loadingCount -= 1;
            if (loadingCount === 0)
                this.setState({
                    lastUpdateTime: new Date(),
                })
        });

        fetch("/api/admin/dashboard?dataType=failure_photo", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
        })
        .then((res) => res.json())
        .then((json) => {
            this.failure_photo = json;
            loadingCount -= 1;
            if (loadingCount === 0)
                this.setState({
                    lastUpdateTime: new Date(),
                })
        });

        fetch("/api/admin/dashboard?dataType=low_battery", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
        })
        .then((res) => res.json())
        .then((json) => {
            this.low_battery = json;
            loadingCount -= 1;
            if (loadingCount === 0)
                this.setState({
                    lastUpdateTime: new Date(),
                })
        });

        fetch("/api/admin/dashboard?dataType=record_count", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
        })
        .then((res) => res.json())
        .then((json) => {
            this.record_count = json;
            loadingCount -= 1;
            if (loadingCount === 0)
                this.setState({
                    lastUpdateTime: new Date(),
                })
        });

        fetch("/api/admin/dashboard?dataType=photo_count", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
        })
        .then((res) => res.json())
        .then((json) => {
            this.photo_count = json;
            loadingCount -= 1;
            if (loadingCount === 0)
                this.setState({
                    lastUpdateTime: new Date(),
                })
        });

        fetch("/api/admin/dashboard?dataType=dumping_outside", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + this.context.accessToken,
            },
        })
        .then((res) => res.json())
        .then((json) => {
            this.dumping_outside = json;
            loadingCount -= 1;
            if (loadingCount === 0)
                this.setState({
                    lastUpdateTime: new Date(),
                })
        });
    }

    componentDidMount() {
        this.loadData();
        //console.log(this.context);
    }

    handleRefresh = (event) => {
        this.loadData();
    }

    render() {
        const { lastUpdateTime } = this.state;

        const pieColors = ["#1976D2", "#ff3333"];
        const barColors = ["#1976D2", "#1976D2", "#1976D2", "#1976D2", "#1976D2", "#1976D2", "#29D276"]

        return (
            <div className="Dashboard">

                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper>
                        <Grid container rowSpacing={0} columnSpacing={0}>
                            <Grid item xs={12} md={10} lg={10}>
                                <Typography component="h2" variant="h6" color="primary" gutterBottom padding={1}>
                                    Update time&nbsp;:&nbsp; 
                                    { lastUpdateTime ? 
                                        <>{moment(lastUpdateTime).format("YYYY-MM-DD HH:mm:ss")}</>
                                        : <>Loading</>
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2} lg={2}>
                                <Button 
                                    variant="contained" 
                                    sx={{ marginTop: 1}}
                                    onClick={this.handleRefresh}
                                >
                                    Refresh
                                </Button>
                            </Grid>
                        </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        { this.vessel_count ?
                            <Paper>
                                <Typography component="h2" variant="subtitle2" color="primary" gutterBottom paddingLeft={1}>Vessels</Typography>
                                <ResponsiveContainer width='100%' aspect={16/9}>
                                <PieChart sx={{ minWidth: 200 }} height={250}>
                                    <Legend  verticalAlign="top" height={36} />
                                    <Pie data={this.vessel_count} 
                                        dataKey="num" 
                                        nameKey="name" 
                                        outerRadius="90%" 
                                        fill="#8884d8" 
                                        label="true"
                                    >{
                                        this.vessel_count.map((entry, index) => <Cell key={`cell-${index}`} fill={pieColors[index]}/>)
                                    }</Pie>
                                </PieChart>
                                </ResponsiveContainer>
                            </Paper>
                        : null
                        }
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                    { this.record_count ?
                        <Paper>
                            <Typography component="h2" variant="subtitle2" color="primary" gutterBottom paddingLeft={1}>Records</Typography>
                            <ResponsiveContainer width='100%' aspect={16/9}>
                            <BarChart sx={{ minWidth: 200 }} height={250} data={this.record_count} >
                                <XAxis dataKey="DAYSHORTNAME" tick={{fontSize: 14}} />
                                <YAxis type="number" domain={[0, 20000]} interval={1} tick={{fontSize: 14}} padding={{ top: 10 }} />
                                <Tooltip />
                                <Bar dataKey="num_record" fill="#ff0000">
                                {
                                    this.record_count.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                                    ))
                                }  
                                </Bar>
                            </BarChart>
                            </ResponsiveContainer>
                        </Paper>
                    : null
                    }
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                    { this.photo_count ?
                        <Paper>
                            <Typography component="h2" variant="subtitle2" color="primary" gutterBottom paddingLeft={1}>Photos</Typography>
                            <ResponsiveContainer width='100%' aspect={16/9}>
                            <BarChart  data={this.photo_count}>
                                <XAxis dataKey="DAYSHORTNAME" tick={{fontSize: 14}} />
                                <YAxis type="number" domain={[0, 1000]} interval={1} tick={{fontSize: 14}} padding={{ top: 10 }} />
                                <Tooltip />
                                <Bar dataKey="num_record" fill="#00ff00">
                                {
                                    this.photo_count.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                                    ))
                                }  
                                </Bar>
                            </BarChart>
                            </ResponsiveContainer>
                        </Paper>
                    : null
                    }
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>         
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 300 }} size="small" aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={3} align="center">No data over 30 mins</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="center">Unit ID</StyledTableCell>
                                        <StyledTableCell align="center">Vessel Name</StyledTableCell>
                                        <StyledTableCell align="center">Aged (hours)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { this.failure_record ?
                                        this.failure_record.map((row) => (
                                            <StyledTableRow key={row.unit_id} >
                                                <StyledTableCell align="center">{row.unit_id}</StyledTableCell>
                                                <StyledTableCell align="center">{row.vessel_name}</StyledTableCell>
                                                <StyledTableCell align="center">{row.aged_hour}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                        : <StyledTableCell align="center" colSpan={3}>No record</StyledTableCell>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>         
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 300 }} size="small" aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={3} align="center">No photo over 30 mins</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="center">Unit ID</StyledTableCell>
                                        <StyledTableCell align="center">Vessel Name</StyledTableCell>
                                        <StyledTableCell align="center">Aged (hours)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { this.failure_photo ?
                                        this.failure_photo.map((row) => (
                                            <StyledTableRow key={row.unit_id} >
                                                <StyledTableCell align="center">{row.unit_id}</StyledTableCell>
                                                <StyledTableCell align="center">{row.vessel_name}</StyledTableCell>
                                                <StyledTableCell align="center">{row.aged_hour}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                        : <StyledTableCell align="center" colSpan={3}>No record</StyledTableCell>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>         
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 300 }} size="small" aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={3} align="center">Low battery (&lt;20V)</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="center">Unit ID</StyledTableCell>
                                        <StyledTableCell align="center">Vessel Name</StyledTableCell>
                                        <StyledTableCell align="center">Battery Voltage (V)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { this.low_battery ?
                                        this.low_battery.map((row) => (
                                            <StyledTableRow key={row.unit_id} >
                                                <StyledTableCell align="center">{row.unit_id}</StyledTableCell>
                                                <StyledTableCell align="center">{row.vessel_name}</StyledTableCell>
                                                <StyledTableCell align="center">{row.battery_voltage}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                        : <StyledTableCell align="center" colSpan={3}>No record</StyledTableCell>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12} md={8} lg={6}>         
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 300 }} size="small" aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={4} align="center">Recent missing data records</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell align="center">Unit ID</StyledTableCell>
                                        <StyledTableCell align="center">Vessel Name</StyledTableCell>
                                        <StyledTableCell align="center">Start Time</StyledTableCell>
                                        <StyledTableCell align="center">End Time</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { this.dumping_outside ?
                                        this.dumping_outside.map((row) => (
                                            <StyledTableRow key={row.unit_id} >
                                                <StyledTableCell align="center">{row.unit_id}</StyledTableCell>
                                                <StyledTableCell align="center">{row.vessel_name}</StyledTableCell>
                                                <StyledTableCell align="center">{moment(row.start_time).format("YYYY-MM-DD HH:mm")}</StyledTableCell>
                                                <StyledTableCell align="center">{moment(row.end_time).format("YYYY-MM-DD HH:mm")}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                        : <StyledTableCell align="center" colSpan={3}>No record</StyledTableCell>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Dashboard;