import * as React from 'react';
import VesselList from './VesselList';
import EditVessel from './EditVessel';
import NewVessel from './NewVessel';

class Vessels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curPage: 0,
            selectedVessel: false,
        }
    }

    handleVesselListOnClickCallback = (vessel_idx) => {
        //console.log("Vessels: selected: unit = " + vessel_idx)
        this.setState({
            curPage: 1,
            selectedVessel: vessel_idx,
        })
    }

    onAddVessel = (event) => {
        event.preventDefault();
        console.log("Add new vessel")
        this.setState({
            curPage: 2,
        })
    }

    onBack = () => {
        this.setState({
            curPage: 0,
        })
    }

    getPage = () => {
        const { curPage, selectedVessel } = this.state;

        switch (curPage) {
            case 0:
                return <VesselList parentCallback={this.handleVesselListOnClickCallback} onAddVessel={this.onAddVessel} />;
            case 1:
                return <EditVessel vessel_idx={selectedVessel} onBack={this.onBack} />;
            case 2:
                return <NewVessel onBack={this.onBack} />
            default:
            throw new Error("Unknown page");
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.getPage()}
            </React.Fragment>
        )
    }
}

export default Vessels;