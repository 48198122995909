import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FrameworkWithSideBar from './components/FrameworkWithSideBar';
//import Dashboard from './components/Dashboard';
import Login from './pages/login/Login';
import Missing from './pages/missing/Missing';
import { ROLES } from './constants';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './pages/unauthorized/Unauthorized';

//React Protected Routes
//https://youtu.be/oUZjO00NkhY

function App() {

    return (
        <BrowserRouter>
            <Routes>
                {/* All Public Page */}
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/Dashboard" element={<FrameworkWithSideBar title="Dashboard"   />} />
                
                {/* Admin, Guest, Viewer */}
                <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN,ROLES.GUEST,ROLES.VIEWER]}   />}>
                    <Route path="/Summary"    element={<FrameworkWithSideBar title="Summary"      />} />
                </Route>

                {/* Everyone, except Guest */}
                <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN,ROLES.BARGE_OWNER,ROLES.PERMIT_HOLDER,ROLES.VIEWER]}   />}>
                    <Route path="/Records"    element={<FrameworkWithSideBar title="Records"      />} />
                    <Route path="/Draughts"   element={<FrameworkWithSideBar title="Draughts"     />} />
                    <Route path="/Activities" element={<FrameworkWithSideBar title="Activities"   />} />
                    <Route path="/Map"        element={<FrameworkWithSideBar title="Map"          />} />
                    <Route path="/Photos"     element={<FrameworkWithSideBar title="Photos"       />} />
                </Route>

                {/* Admin, Barge owner and Permit holder Only */}
                <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN,ROLES.BARGE_OWNER,ROLES.PERMIT_HOLDER,ROLES.VIEWER]} />}>
                    <Route path="/changepassword" element={<FrameworkWithSideBar title="ChangePassword" />} />
                </Route>
                
                {/* Admin Only */}
                <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.VIEWER]} />}>
                    <Route path="/Zones"   element={<FrameworkWithSideBar title="Zones"     />} />
                    <Route path="/Vessels" element={<FrameworkWithSideBar title="Vessels"   />} />
                    <Route path="/Permits" element={<FrameworkWithSideBar title="Permits"   />} />
                    <Route path="/Users"   element={<FrameworkWithSideBar title="Users"     />} />
                </Route>

                {/* For All other page show 404 page */}
                <Route path="/*" element={<Missing />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;