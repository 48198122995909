import * as React from 'react';
import UserList from './UserList';
import EditUser from './EditUser';
import NewUser from './NewUser';

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curPage: 0,
            selectedUser: false,
        }
    }

    handleUserListOnClickCallback = (userID) => {
        //console.log("User: selected: " + userID)
        this.setState({
            curPage: 1,
            selectedUser: userID,
        })
    }

    onAddUser = (event) => {
        event.preventDefault();
        console.log("Add new user")
        this.setState({
            curPage: 2,
        })
    }

    onBack = () => {
        this.setState({
            curPage: 0,
        })
    }

    getPage = () => {
        const { curPage, selectedUser } = this.state;

        switch (curPage) {
            case 0:
                return <UserList parentCallback={this.handleUserListOnClickCallback} onAddUser={this.onAddUser} onBack={this.onBack} />;
            case 1:
                return <EditUser userID={selectedUser} onBack={this.onBack} />;
            case 2:
                return <NewUser onBack={this.onBack} />
                default:
                throw new Error("Unknown page");
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.getPage()}
            </React.Fragment>
        )
    }
}

export default Users;