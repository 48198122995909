import axios from 'axios';

// const BASE_URL = 'http://localhost:8081'; //original
const BASE_URL = 'https://femu-pro-svr.msc-service.net/';
// const BASE_URL = 'https://localhost';

export const authAPI = axios.create({
    baseURL: BASE_URL
});

export const authPrivateAPI = axios.create({
    baseURL: BASE_URL,
    headers: { 
        'Content-Type': 'application/json',
    },
    withCredentials: true
});