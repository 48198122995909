import React from "react";
import {
    Grid, Button, FormControl, InputLabel, Select, MenuItem, TextField,
    Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Box, Stack
} from '@mui/material';
import AlertBar from '../components/AlertBar';
import styled from "styled-components";
import { DataGrid, useGridApiContext } from '@mui/x-data-grid';
import PreviewMap from "../components/PreviewMap";
import proj4 from "proj4";

import AuthContext from "../contexts/AuthProvider";
import {ROLES} from "../constants";

const HK80_projection = "+proj=tmerc +lat_0=22.3121333333333 +lon_0=114.178555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +towgs84=-162.619,-276.959,-161.764,-0.067753,2.243648,1.158828,-1.094246 +units=m +no_defs +type=crs";
const WGS84_projection = "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees";

// Ugly, temporary storage of changed field id and value
// to be recalled by onCellEditStop()
var lastUpdateId = null;
var lastUpdateField = null;
var lastUpdateValue = null;

function EditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleChange = async (event) => {
        await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
//      apiRef.current.stopCellEditMode({ id, field });
//        console.log("id = " + id + ", field = " + field + ", value = " + event.target.value)
        lastUpdateId = id;
        lastUpdateField = field;
        lastUpdateValue = event.target.value;;
    };
  
    return (
      <TextField
        value={value}
        onChange={handleChange}
        size="small"
        autoFocus
      />
    );
}

const renderEditInputCell = (params) => {
    return <EditInputCell {...params} />;
};

export const GridBreak = styled.div`
    width: 100%
`;

const columns = [
    { field: 'id', headerName: 'No.', width: 50, editable: false, },
    { field: 'N', headerName: 'Norhting', width: 120, editable: true, renderEditCell: renderEditInputCell },
    { field: 'E', headerName: 'Easting', width: 120, editable: true, renderEditCell: renderEditInputCell },
    { field: 'lat', headerName: 'Latitude', width: 120, editable: true, renderEditCell: renderEditInputCell },
    { field: 'lon', headerName: 'Longitude', width: 120, editable: true, renderEditCell: renderEditInputCell },
]

  
class EditZone extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props)
        this.state = {
            zone_idx: props.zone_idx,
            zoneInfo: false,        /* zoneInfo only stores zone_name and zone_type */
            updatedInfo: false,     /* a copy of existing info for storing and checking any change */
            zoneVertex: false,
            alertType: "Info",
            alertMessage: "Please make sure the information is correct before update!",
            dialogOpen: false,
            existingZoneList: false,
        }
    }

    componentDidMount() {
        const { zone_idx, zoneInfo, existingZoneList } = this.state;

        if (existingZoneList === false) {
            fetch("/api/zone/names", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                //console.log(json)
                if (json.length > 0) {
                    this.setState({
                        existingZoneList: json,
                    })
                }
            })
        }

        if (zoneInfo === false) {
            fetch("/api/zone/detail/" + zone_idx, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },    
            })
            .then((res) => res.json())
            .then((json) => {
                //console.log(json)
                if (json.length > 0) {
                    this.setState({
                        zoneInfo: { "idx": json[0].idx, 
                                    "zone_name": json[0].zone_name, 
                                    "zone_type": json[0].zone_type,
                                    "zone_status": json[0].zone_status,
                                  },
                        updatedInfo: { "idx": json[0].idx, 
                                       "zone_name": json[0].zone_name, 
                                       "zone_type": json[0].zone_type,
                                       "zone_status": json[0].zone_status,
                                     },
                        zoneVertex: JSON.parse(JSON.stringify(json[0].vertex)),
                        updatedVertex: JSON.parse(JSON.stringify(json[0].vertex)),
                        //updatedInfo: { ...json[0] },  
                    })
                }
            })
        }
    }

    handleTextFieldOnBlur = (event) => {
        let { updatedInfo } = this.state;
        updatedInfo[event.target.id] = event.target.value;
    }

    handleSelectOnChange = (event, field_id) => {
        let { updatedInfo } = this.state;
        updatedInfo[field_id] = event.target.value;
    }

    /*
    handleCheckboxOnChange = (event) => {
        let { updatedInfo } = this.state;
        updatedInfo[event.target.id] = event.target.checked ? 'Y' : 'N';
    }

    handleDatePickerSelectedDateCallback = (newValue, field_id) => {
        let { updatedInfo } = this.state;
        //console.log("handleDatePickerSelectedDateCallback : " + field_id)
        updatedInfo[field_id] = new Date(newValue).toISOString();
    }
    */

    handleButtonOnBack = () => {
        const { zoneInfo, updatedInfo, zoneVertex, updatedVertex } = this.state;
        //console.log("onBack()");
        //console.log(zoneInfo);
        //console.log(updatedInfo);
        if ( (JSON.stringify(zoneInfo) === JSON.stringify(updatedInfo)) && (JSON.stringify(zoneVertex) === JSON.stringify(updatedVertex)) ) {
            // No change, go back
            this.props.onBack();
        } else {
            this.setState({
                dialogOpen: true,
            })
        }
        
    }

    handleDialogClose = (event) => {
        this.setState({
            dialogOpen: false,
        })
        if (event.target.value === "discard") {
            this.props.onBack();
        }
    }

    // ====================================
    // Validations
    fieldValidations(info) {
        const { existingZoneList } = this.state;

        const regExNoSpChar = new RegExp(/^[^*<>!%'"+=;^$]*$/);         // Not contains special characters
        const regExHK80 = new RegExp(/^8\d{5}\.{0,1}\d{0,2}$/);
        const regExWGSLat = new RegExp(/^2[1-3]\.{0,1}\d{0,6}$/);
        const regExWGSLng = new RegExp(/^11[3-4]\.{0,1}\d{0,6}$/);
        var validationFailed = false;

        // Check permit number should contain special character and max. 50 characters
        if (!regExNoSpChar.test(info.zoneName) || (info.zoneName.length === 0) || (info.zoneName.length > 50)) {
            this.setState({
                alertType: "Error",
                alertMessage: "Zone name should not contain special character, cannot be empty and maximum 50 characters!",
            })
            return false;
        }
        existingZoneList.forEach(existing => {
            if (existing.zone_name === info.zoneName) {
                if (existing.idx !== info.idx) {
                    this.setState({
                        alertType: "Error",
                        alertMessage: `Zone name '${info.zoneName}' has been used, please use an unique zone name!`,
                    })
                    validationFailed = true;
                    return;
                }
            }
        });
        if (validationFailed) return false;

        // Check zone type is selected
        if (info.zoneType === "") {
            this.setState({
                alertType: "Error",
                alertMessage: "Please select zone type!",
            })
            return false;
        }

        // Validate zone vertex
        info.zoneVertex.forEach(row => {
            if (row.E !== "" && row.N !== "") { 
                if (!regExHK80.test(row.N) || !regExHK80.test(row.E)) {
                    this.setState({
                        alertType: "Error",
                        alertMessage: "Invalid HK80 coordinates",
                    })
                    validationFailed = true;
                    return;
                }
            }
            if (row.lat !== "" && row.lon !== "") {
                if (!regExWGSLat.test(row.lat) || !regExWGSLng.test(row.lon)) {
                    this.setState({
                        alertType: "Error",
                        alertMessage: "Invalid WGS84 coordinates",
                    })
                    validationFailed = true;
                    return;
                }
            }
        })
        if (validationFailed) return false;

        return true;
    }

    handleButtonOnSaveChanges = () => {
        const { zoneInfo, updatedInfo, zoneVertex, updatedVertex } = this.state;

        // Validate inputs
        const info = {
            idx: updatedInfo.idx,
            zoneName: updatedInfo.zone_name,
            zoneType: updatedInfo.zone_type,
            zoneVertex: updatedVertex,
        };

        if (!this.fieldValidations(info)) return;

        // All passed
        if ( (JSON.stringify(zoneInfo) === JSON.stringify(updatedInfo)) && (JSON.stringify(zoneVertex) === JSON.stringify(updatedVertex)) ) {
            // No change, show warning
            this.setState({
                alertType: "Warning",
                alertMessage: "No change found, nothing to save!",
            })
        } else {
            var everything = {
                "idx": updatedInfo["idx"],
                "zone_name": updatedInfo["zone_name"],
                "zone_type": updatedInfo["zone_type"],
                "zone_status": updatedInfo["zone_status"],
                "vertex": updatedVertex
            }
            //console.log("Update zone")
            //console.log(JSON.stringify(everything))
            
            fetch('/api/zone/update', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + this.context.accessToken,
                },
                body: JSON.stringify(everything),
            })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    alertType: json.Result,
                    alertMessage: json.Message,
                })
                if (json.Result === "Success") {
                    setTimeout(this.props.onBack, 2000);
                }
            })
        }
    }

    addVertex = () => {
        const { updatedVertex } = this.state;
        
        var newVertex = [];        
        updatedVertex.map((row) => (
            newVertex.push(row)
        ))
        
        newVertex.push({'id': newVertex.length + 1, 'N': "", 'E': "", 'lat': "", 'lon': ""})
        this.setState({
            updatedVertex: newVertex,
        })
    }


    handleDataGridEdited = () => {
        const { updatedVertex } = this.state;
        
        const regExHK80 = new RegExp(/^8\d{5}\.{0,1}\d{0,2}$/);
        const regExWGSLat = new RegExp(/^2[1-3]\.{0,1}\d{0,6}$/);
        const regExWGSLng = new RegExp(/^11[3-4]\.{0,1}\d{0,6}$/);

        var newVertex = [];
        updatedVertex.map((row) => (
            newVertex.push(row)
        ))
        // console.log("lastUpdated: " + lastUpdateId + ", " + lastUpdateField + ", " + lastUpdateValue);
        if (lastUpdateId !== null) {
            newVertex[lastUpdateId-1][lastUpdateField] = lastUpdateValue;
            if ((lastUpdateField === 'N') || (lastUpdateField === 'E')) {
                if (!regExHK80.test(lastUpdateValue)) {
                    this.setState({
                        alertType: "Error",
                        alertMessage: "Invalid HK80 coordinates",
                    })
                    return;
                }
                var N = newVertex[lastUpdateId-1]['N'];
                var E = newVertex[lastUpdateId-1]['E'];
                if ((N.length > 0) && (E.length > 0)) {
                    var wgs84 = proj4(HK80_projection, WGS84_projection, [ parseFloat(E), parseFloat(N) ]);
                    newVertex[lastUpdateId-1]['lat'] = wgs84[1].toFixed(5).toString();
                    newVertex[lastUpdateId-1]['lon'] = wgs84[0].toFixed(5).toString();
                } 
            } else
            if ((lastUpdateField === 'lat') || (lastUpdateField === 'lon')) {
                if ((lastUpdateField === 'lat') && !regExWGSLat.test(lastUpdateValue)) {
                    this.setState({
                        alertType: "Error",
                        alertMessage: "Invalid WGS84 coordinates",
                    })
                    return;
                }
                if ((lastUpdateField === 'lon') && !regExWGSLng.test(lastUpdateValue)) {
                    this.setState({
                        alertType: "Error",
                        alertMessage: "Invalid WGS84 coordinates",
                    })
                    return;
                }
                var lat = newVertex[lastUpdateId-1]['lat'];
                var lon = newVertex[lastUpdateId-1]['lon'];
                if ((lat.length > 0) && (lon.length > 0)) {
                    var hk80 = proj4(WGS84_projection, HK80_projection, [ parseFloat(lon), parseFloat(lat) ]);
                    newVertex[lastUpdateId-1]['N'] = hk80[1].toFixed(0).toString();
                    newVertex[lastUpdateId-1]['E'] = hk80[0].toFixed(0).toString();
                }
            }
            //console.log(newVertex[lastUpdateId-1]);
            this.setState({
                updatedVertex: newVertex,
                alertType: "Info",
                alertMessage: "Please make sure the information is correct before update!",
            })
        }        
    }

    render() {
        const { zoneInfo, updatedVertex, alertType, alertMessage, dialogOpen } = this.state;

        /* Data not loaded yet */
        if (zoneInfo === false) return (<div>No data</div>)

        const isAdmin = this.context.hasRole([ROLES.ADMIN]);
        
        return (
            <div>
                { /* Alert bar */
                  isAdmin ?
                    <AlertBar alertType={alertType} alertMessage={alertMessage} />
                  : null
                }

                { /* Dialog */}
                <Dialog
                    open={dialogOpen}
                    onClose={this.handleDialogClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        Discard changes?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog_description">
                            Change(s) detected, discard changes without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button value="discard" onClick={this.handleDialogClose}>Discard</Button>
                        <Button value="keep_editing" onClick={this.handleDialogClose} autoFocus>Keep Editing</Button>
                    </DialogActions>
                </Dialog>

                { /* Form */}
                <div className="EditForm">
                    <Grid container rowSpacing={5} columnSpacing={3}>
                        { /* Large screen row 1 */}
                        <Grid item xs={12} md={8} lg={6}>
                            <TextField
                                required
                                id="zone_name"
                                name="zone_name"
                                label="Zone Name"
                                fullWidth
                                variant="standard"
                                defaultValue={zoneInfo.zone_name}
                                onBlur={this.handleTextFieldOnBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl required fullWidth>
                                <InputLabel>Zone Type</InputLabel>
                                    <Select
                                        label="zone_type"
                                        defaultValue={zoneInfo.zone_type}
                                        onChange={event => this.handleSelectOnChange(event, "zone_type")}
                                    >
                                        <MenuItem key='D' value='D'>Dumping Zone</MenuItem>
                                        <MenuItem key='L' value='L'>Loading Zone</MenuItem>
                                    </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl required fullWidth>
                                <InputLabel>Zone Status</InputLabel>
                                    <Select
                                        label="zone_status"
                                        defaultValue={zoneInfo.zone_status}
                                        onChange={event => this.handleSelectOnChange(event, "zone_status")}
                                    >
                                        <MenuItem key='A' value='A'>Active</MenuItem>
                                        <MenuItem key='S' value='S'>Suspended</MenuItem>
                                    </Select>
                            </FormControl>
                        </Grid>

                        <GridBreak />

                        { /* Large screen row 2 */}
                        <Grid item xs={12} md={6} lg={6}>
                            <Box sx={{ height: 400, width: '100%'}}>
                            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                                <Button size="small" onClick={this.addVertex}>
                                    Add a row
                                </Button>
                            </Stack>
                                <DataGrid  
                                    rows={updatedVertex}
                                    columns={columns}
                                    pageSize={20}
                                    disableRowSelectOnClick
                                    hideFooter={true}
                                    hideFooterPagination={true}
                                    disableColumnMenu={true} 
                                    onCellEditStop={this.handleDataGridEdited}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <PreviewMap vertex={updatedVertex} />
                        </Grid>

                        <GridBreak />


                        <Grid item xs={12} md={4} lg={3}>
                            <Button variant="outlined" onClick={this.handleButtonOnBack}>
                                Back
                            </Button>
                            <span>&nbsp;&nbsp;</span>
                            { isAdmin ?
                                        <Button variant="contained" onClick={this.handleButtonOnSaveChanges}>
                                            Save Changes
                                        </Button>
                                    : null
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default EditZone;