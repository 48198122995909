import { Button,Box,TextField,Alert, Typography } from '@mui/material'
import React,{useState, useRef, useEffect} from 'react'
import useAuth from '../../hooks/useAuth';

import './changePassword.scss'

function ChangePassword() {
    const auth = useAuth();
    
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword    , setNewPassword    ] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errMsg         , setErrMsg         ] = useState('');
    const [isSuccess      , setIsSuccess      ] = useState(false);

    const [currentPasswordError,setCurrentPasswordError] = useState(false);
    const [newPasswordError    ,setNewPasswordError    ] = useState(false);
    const [confirmPasswordError,setConfirmPasswordError] = useState(false);

    const currentPasswordRef = useRef();
    const newPasswordRef     = useRef();
    const confirmPasswordRef = useRef();
    const successMsgRef      = useRef();
    const errMsgRef          = useRef();

    useEffect(() => {
        setCurrentPasswordError(false);
        setNewPasswordError(false);
        setConfirmPasswordError(false);
        setErrMsg('');
        setIsSuccess(false);

    }, [currentPassword,
        newPassword,
        confirmPassword])
    
    const checkPasswordPattern = (password) => {
        let result = false;
        // const password_regex=  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{10,50}$/;
        const password_regex=  /^(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,50}$/;

        if(password_regex.test(password)){
            result = true;
        }
    
        return result;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("handleSubmit");
        let hasError = false;

        if (!currentPassword) {
            console.log("Error 1")
            hasError = true;
            setCurrentPasswordError(true);
        }    

        if (!newPassword) {
            console.log("Error 2")
            hasError = true;
            setNewPasswordError(true);
        }     

        if (!checkPasswordPattern(newPassword)){
            console.log("tooSimple");
            hasError = true;
            setNewPasswordError(true);
        }

        if (!confirmPassword) {
            console.log("Error 3")
            hasError = true;
            setConfirmPasswordError(true);
        }   

        if (newPassword !== confirmPassword) {
            console.log("Error 4")
            hasError = true;
            setConfirmPasswordError(true);
        }  

        if (hasError) return;

        try {
            let response = await auth.changePassword(currentPassword,newPassword);

            console.log(response)

            switch (response?.data?.resultCode) {
                case 1000:
                    setIsSuccess(true);
                    break;

                case 1006:
                    currentPasswordRef.current.focus();
                    setCurrentPasswordError(true);
                    setErrMsg("Please check your password");
                    break;

                case 1007:
                    newPasswordRef.current.focus();
                    setNewPasswordError(true);
                    setErrMsg("Can't reuse last 8th passwords");
                    break;

                case 1009:
                    newPasswordRef.current.focus();
                    setNewPasswordError(true);
                    setConfirmPasswordError(true);
                    // setErrMsg("new password too simple");
                    break;
            
                default:
                    break;
            }
        }
        catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Change Password Failed');
            }
        }
    };

    return (
        <div id="divChangePassword">
            <Typography variant='h4'>
                Change Password
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField 
                    id="currentPassword" 
                    ref={currentPasswordRef} 
                    label="Current Password"
                    name="currentPassword"
                    variant="outlined" 
                    required
                    type="password"
                    error={currentPasswordError}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    value={currentPassword}
                />
                <TextField 
                    id="newPassword" 
                    ref={newPasswordRef} 
                    label="New Password"
                    name="newPassword"
                    variant="outlined" 
                    required
                    type="password"
                    error={newPasswordError}
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                    helperText="At least 10 characters with numbers, small letters, captial letters and special characters"
                />
                <TextField 
                    id="confirmPassword" 
                    ref={confirmPasswordRef} 
                    label="Confirm Password"
                    name="confirmPassword"
                    variant="outlined" 
                    required
                    type="password"
                    error={confirmPasswordError}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                />
                <Button type="submit" variant="contained" >Change</Button>
            </Box>
            {
                errMsg?
                    (<Alert ref={errMsgRef} severity="error">{errMsg}</Alert>)
                    :
                    null
            }
            {    
                isSuccess?
                    (<Alert ref={successMsgRef} severity="success">Password Changed</Alert>)
                    :
                    null
            }
        </div>
    )
}

export default ChangePassword