const ROLES = {
    'GUEST'         : "0",
    'ADMIN'         : "1",
    'BARGE_OWNER'   : "2",
    'PERMIT_HOLDER' : "3",
    'VIEWER'        : "4",
}

const VERSION = "1.1";
const BUILD = "012";

export {ROLES,VERSION,BUILD};