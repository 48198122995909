import { createContext, useState} from "react";
import {authAPI,authPrivateAPI} from "../api/authAPI";
import jwt_decode from "jwt-decode";

// React User Login and Authentication with Axios
// https://youtu.be/X3qyxo_UTR4

// Best Practices for React Data Security, Logins, Passwords, JWTs 
// https://youtu.be/3QaFEu-KkR8

// React Login Authentication with JWT Access, Refresh Tokens, Cookies and Axios 
// https://youtu.be/nI8PYZNFtac

const AuthContext = createContext({
    user: {username:null,displayName:null,userStatus:null},
    isLoggedIn: () => {},
    logout: () => {},
    login: async (username, password) => {},
    hasRole: (allowedRoles) => {},
    changePassword: async (password, newPassword) => {},
    accessToken: null
  });

export const AuthProvider = ({ children }) => {
    const LOGIN_URL = '/auth/login';
    const CHANGE_PASSWORD_URL = '/auth/changePassword';

    const [user, setUser] = useState({});
    const [accessToken, setAccessToken] = useState("");

    const isLoggedInHandler = () =>
    {
        let result = false;

        try{
            if (accessToken) {

                let decoded = jwt_decode(accessToken);

                if (Date.now() >= decoded.exp * 1000) {
                    console.log("Token Expired!");
                    setUser(false);
                    setAccessToken(false);
                    result = false;
                }
                else 
                {
                    result = true;
                }
            } else {
                result = false;
            }
        }
        catch(err)
        {
            result = false;
        }

        return result;
    };

    const loginHandler = async (username, password) => {
        // console.log("Login Handler!!");
        const response = await authAPI.post(LOGIN_URL,
            JSON.stringify({ "username":username, "password": password }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: false
            }
        );

        //console.log(response?.data);
        const token = response?.data?.token;
        // const roles = response?.data?.roles;
        const displayName = response?.data?.displayName;
        const userStatus = response?.data?.userStatus;
        
        setUser({
            "username": username,
            "displayName": displayName,
            "userStatus": userStatus,
            // "roles": roles,
        });
        setAccessToken(token);
    };

    const changePasswordHandler = async (password,newPassword) => {
        // console.log("Change Password Handler!!");

        const response = await authPrivateAPI.post(CHANGE_PASSWORD_URL,
            JSON.stringify({ "password": password , "newPassword": newPassword}),
            {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + accessToken,
                },
                withCredentials: false
            }
        );
        return response;
    };

    const logoutHandler = () => {
        setUser(false);
        setAccessToken(false);
    };

    const hasRoleHandler = (allowedRoles) =>{
        let decoded;

        try{
            decoded = jwt_decode(accessToken);
            // console.log(accessToken);
            // console.log(decoded);

        }catch(err){
            return false;
        }

        return decoded?.roles?.find(role => allowedRoles?.includes(role));
    }

    return (
        <AuthContext.Provider value={{ 
            user: user,
            accessToken: accessToken,
            isLoggedIn     : isLoggedInHandler,
            logout         : logoutHandler,
            login          : loginHandler,
            hasRole        : hasRoleHandler,
            changePassword : changePasswordHandler,
         }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;