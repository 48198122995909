import React from "react";
import ReactDOM from "react-dom/client";
import App from './App';
import { AuthProvider } from './contexts/AuthProvider';
// import { disableReactDevTools } from '@fvilers/disable-react-devtools';
// disableReactDevTools();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <App />
        </AuthProvider>
    </React.StrictMode>
);
