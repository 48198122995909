import React, { useEffect }  from 'react';
import { Paper } from '@mui/material';
import { MapContainer, TileLayer, Polygon, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import "leaflet-polylinedecorator";

const dumpingZoneAttribute = { color: 'brown', weight: 1.0 }

// This function only adjust map center 
// It does not draw anything
function AdjustMap({newCenter}) {
    const map = useMap();

    useEffect(() => {
        if (!map) return;
        //console.log(newCenter);
        map.panTo(new L.LatLng(newCenter[0], newCenter[1]));
    }, [map, newCenter]);

    return null;
}


class PreviewMap extends React.Component {

    render() {
        if (this.props.vertex.length === 0) return (<div></div>)
        //console.log("PreviewMap render()")

        var pts = null;
        var mapCenter = [22.31, 114.15];
        if (this.props.vertex.length > 0) {
            pts = [];
            var mlat = 0;
            var mlon = 0;
            this.props.vertex.forEach((row) => {
                if ((row.lat.length > 2) && (row.lon.length > 3)) {
                    var lat = parseFloat(row.lat);
                    var lon = parseFloat(row.lon);
                    if ((lat > 21) && (lat < 23) && (lon > 112) && (lon < 115)) {
                        pts.push([parseFloat(row.lat), parseFloat(row.lon)]);
                        mlat += lat;
                        mlon += lon;
                    }
                }
            });
            //console.log("PreviewMap:render():")
            //console.log(pts)
            if (pts.length > 0) {
                mlat /= pts.length;
                mlon /= pts.length;
                mapCenter = [mlat, mlon];
                //console.log(mapCenter)
            }
        }

        return (
            <div className='MapView' style={{ marginTop: 10 }}>
            <Paper elevation={3}>
                <MapContainer
                    center={mapCenter}
                    zoom={14}
                    scrollWheelZoom={false}
                    style={{ height: 420, width: "100%" }}
                >
                    <TileLayer
                        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenSeaMap</a> contributors'
                        opacity={0.75}
                    />
                    <TileLayer
                        url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
                        opacity={0.25}
                    />
                    {
                        ((pts !== null) && (pts.length > 3)) ? (
                            <Polygon key="preview" pathOptions={dumpingZoneAttribute} positions={pts} />
                        ) : null
                    }
                    <AdjustMap newCenter={mapCenter} />
                </MapContainer>
            </Paper>
            </div>
        )
    }
}

export default PreviewMap;