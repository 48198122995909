import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ViewListIcon from '@mui/icons-material/ViewList';
import DvrIcon from '@mui/icons-material/Dvr';
import MapIcon from '@mui/icons-material/Map';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VesselIcon from '@mui/icons-material/DirectionsBoat';
//import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ZoneIcon from '@mui/icons-material/SouthAmerica'
import ChartIcon from '@mui/icons-material/SsidChart';
import UserIcon from '@mui/icons-material/People';
import KeyIcon from '@mui/icons-material/Key';
import { useNavigate } from 'react-router-dom';
import { ROLES } from '../constants';
import useAuth from '../hooks/useAuth';

const MainListItems = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    if (auth.user.userStatus === 'F')
        return (
            <React.Fragment>
                <ListItemButton onClick={() => { navigate('/Dashboard',{replace:true});}}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton onClick={() => { navigate('/changepassword',{replace:true});}}>
                <ListItemIcon>
                    <KeyIcon />
                </ListItemIcon>
                <ListItemText primary="Change Password" />
                </ListItemButton>
            </React.Fragment>        
        )

    return (
    <React.Fragment>
        <ListItemButton onClick={() => { navigate('/Dashboard',{replace:true});}}>
        <ListItemIcon>
            <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
        </ListItemButton>

        <ListItemButton onClick={() => { navigate('/Summary',{replace:true});}}>
        <ListItemIcon>
            <SummarizeIcon />
        </ListItemIcon>
        <ListItemText primary="Summary" />
        </ListItemButton>

        { auth.hasRole([ROLES.ADMIN,ROLES.BARGE_OWNER,ROLES.PERMIT_HOLDER,ROLES.VIEWER]) ? (
        <React.Fragment>
            <ListItemButton onClick={() => { navigate('/Records',{replace:true});}}>
            <ListItemIcon>
                <ViewListIcon />
            </ListItemIcon>
            <ListItemText primary="Records" />
            </ListItemButton>

            <ListItemButton onClick={() => { navigate('/Draughts',{replace:true});}}>
            <ListItemIcon>
                <ChartIcon />
            </ListItemIcon>
            <ListItemText primary="Draughts" />
            </ListItemButton>

            <ListItemButton onClick={() => { navigate('/Activities',{replace:true});}}>
            <ListItemIcon>
                <DvrIcon />
            </ListItemIcon>
            <ListItemText primary="Activities" />
            </ListItemButton>

            <ListItemButton onClick={() => { navigate('/Map',{replace:true});}}>
            <ListItemIcon>
                <MapIcon />
            </ListItemIcon>
            <ListItemText primary="Map" />
            </ListItemButton>

            <ListItemButton onClick={() => { navigate('/Photos',{replace:true});}}>
            <ListItemIcon>
                <CameraAltIcon />
            </ListItemIcon>
            <ListItemText primary="Photos" />
            </ListItemButton>
        </React.Fragment>
        ) : null
    }
    </React.Fragment>
    )
};

const SecondaryListItems = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    if (auth.user.userStatus === 'F')
        return (<div />)

    return (
        auth.hasRole([ROLES.ADMIN,ROLES.VIEWER]) ? (
            <React.Fragment>
                <ListSubheader component="div" inset>
                Settings
                </ListSubheader>
                <ListItemButton onClick={() => { navigate('/Vessels',{replace:true});}}>
                <ListItemIcon>
                    <VesselIcon />
                </ListItemIcon>
                <ListItemText primary="Vessels" />
                </ListItemButton>

                <ListItemButton onClick={() => { navigate('/Permits',{replace:true});}}>
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Permits" />
                </ListItemButton>

                <ListItemButton onClick={() => { navigate('/Zones',{replace:true});}}>
                <ListItemIcon>
                    <ZoneIcon />
                </ListItemIcon>
                <ListItemText primary="Zones" />
                </ListItemButton>
                
                { auth.hasRole([ROLES.ADMIN]) ? (
                        <React.Fragment>
                            <ListItemButton onClick={() => { navigate('/Users',{replace:true});}}>
                            <ListItemIcon>
                                <UserIcon />
                            </ListItemIcon>
                            <ListItemText primary="Users" />
                            </ListItemButton>
                        </React.Fragment>
                    ) : null                
                }
            </React.Fragment>
        ) : null
    )
};

export {MainListItems, SecondaryListItems};

