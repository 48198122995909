import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

class MUIdateTimePickers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initial === null ? "" : props.initial,
      label: props.label === null ? "Select date/time" : props.label,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    // console.log("MUIdateTimePickers > handleChange > " + new Date(date).toLocaleString())
    this.setState({
      value: date
    })
    this.props.parentCallback(new Date(date))
  }

  render() {
    const { value, label } = this.state;

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack spacing={3}>
          {
            this.props.dateOnly ? (
              <DatePicker
                label={label}
                value={value}
                onChange={this.handleChange}
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => <TextField {...params} />}
              />
            ) : (
              <DateTimePicker
                label={label}
                value={value}
                onChange={this.handleChange}
                inputFormat="YYYY-MM-DD HH:mm"
                renderInput={(params) => <TextField {...params} />}
              />
            )
          }
        </Stack>
      </LocalizationProvider>
    );
  }
}

export default MUIdateTimePickers;